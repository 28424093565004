import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  }
});

const BasePapper = ({ classes, ...props }) => {
  return (
    <Paper classes={classes} {...props}/>
  );
};

export default withStyles(styles)(BasePapper);