import isFloatHandler from "../helpers/isFloatHandler";

export function maxLengthValidation(maxLength, message) {
  return ({ file }={}) => {
    if (!file) {
      return;
    }
    if(file.size >= maxLength) {
      return message
    }
    return false;
  }
}

export function fileIsRequiredValidation(message='File is required') {
  return ({ file, fileName }={}) => (!file && !fileName) ? message : false;
}

export function combineValidators(...validators) {
  return (item, state) => {
    let message;
    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i];
      message = validator(item, state);
      if (message) {
        return message;
      }
    }
  }
}

export function floatValidation(message='Number required') {
  return value => isFloatHandler(value) ? false : message;
}

export function fieldIsRequired(message='Field is required') {
  return value => !!value ? false : message;
}

export function minLengthValidator(minLength, message=`Min length is ${minLength}`) {
  return value => {
    if (value && value.length < minLength) {
      return message
    }
    return false;
  }
}

export default function equalToValidation(field, message='Field must be equal to') {
  return (value, state) => value === state[field] ? false : message;
}