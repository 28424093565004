import ModelWithFiles from "../base/ModelWithFiles";

export default class DefaultContentModel extends ModelWithFiles {

  mapProps({
    fileName,
    thumbnail,
    type,
    _id,
    name,
  }) {
    const newPropsObject = {
      fileName: fileName || this.fileName,
      thumbnail: thumbnail || this.thumbnail,
      id: _id || this.id,
      type,
      name,
    }
    if (this.type !== type) {
      newPropsObject.fileName = fileName;
    }
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      fileName,
      text,
      thumbnail,
      type,
      name,
    } = this;
    return {
      fileName,
      text,
      thumbnail,
      type,
      name,
    };
  }

  getFilesToUpload() {
    const files = {
      fileName: this.fileName,
      thumbnail: this.thumbnail,
    }
    return files;
  }

  setFilenames({ fileName, thumbnail }) {
    this.fileName = fileName;
    this.thumbnail = thumbnail
  }
}
