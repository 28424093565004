import React from 'react';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemLink from '../../Components/ListItemLink';
import DrawerHeader from './DrawerHeader';
import DrawerCampaignsLinks from './DrawerCampaignsLinks';


const styles = theme => ({
  drawer: {
    flexShrink: 0,
  },
  primaryBackground: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  basicBackground: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  }
})

const CustomDrawer = ({ classes, routes, color, widthClass }) => {
  const primary = color === 'primary';
  return (
    <Drawer
      className={classNames(classes.drawer, widthClass)}
      variant="permanent"
      classes={{
        paper: classNames(
          widthClass,
          {[classes.primaryBackground]: primary}
        ),
      }}
    >
      <DrawerHeader
        classes={{
          avatar: classNames({
            [classes.basicBackground]: primary
          })
        }}
      />
      <List>
        {routes
          .filter(({ invisible }) => !invisible)
          .map((route, index) => (
            !route.divider ? (
              <ListItemLink
                text={route.sidebarText}
                icon={route.sidebarIcon}
                to={route.path}
                key={index}
                classes={{
                  text: classes.primaryBackground && primary
                }}
              />
            ) : (
              <Divider key={index}/>
            )
          ))}
      </List>
      <DrawerCampaignsLinks/>
    </Drawer>
  );
};

export default withStyles(styles)(CustomDrawer);