import { useState, useCallback, useEffect } from "react";
import useDebounce from "./useDebounce";

export default function useLoadOptions(container, {
  limit = 30,
  debounce = 500
} = {}, searchOptions = {}) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, debounce);
  const [options, setOptions] = useState(container.list);

  const setOptionsFromContainer = useCallback(() => {
    setOptions(container.list.map(({ name, id }) => ({ key: id, label: name })));
  })

  useEffect(() => {
    setOptionsFromContainer();
  }, [container.list])

  const handleInputChanged = useCallback((value) => {
    setSearch(value);
  }, [setSearch]);

  const loadOptions = useCallback(async () => {
    await container.loadList({
      limit,
      search: debouncedSearch || undefined,
      ...searchOptions,
    });
    setOptionsFromContainer();
  }, [container.loadList, debouncedSearch])

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

return { options, handleInputChanged };
}
