import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import VisuallyHidden from './VisuallyHidden';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: theme.spacing.unit * 2,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.07),
    '&:hover, &:focus-within': {
      backgroundColor: fade(theme.palette.common.black, 0.15),
    },
  },
  searchInput: {
    paddingLeft: theme.spacing.unit * 1,
    color: 'inherit',
    width: '100%',
  }
})

const SearchToolbar = ({
  children,
  classes,
  onChange,
  search,
  component
}) => {
  return (
    <Toolbar className={classes.root}>
      <Typography component={component || "h2"} variant="h6">
        {children}
      </Typography>
      <div className={classes.search}>
        <SearchIcon/>
        <VisuallyHidden htmlFor="search" component="label">
          Search
        </VisuallyHidden>
        <InputBase
          className={classes.searchInput}
          onChange={onChange}
          value={search}
          id="search"
          placeholder="Search"
        />
      </div>
    </Toolbar>
  );
};

export default withStyles(styles)(SearchToolbar);