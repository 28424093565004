import getInstance from "./instance";

export const getItem = async (params) => {
  const response = await getInstance()
    .get(`/api/v1/admin/ground-element`, {
      params
    });
  return response.data;
}

export const update = async data => getInstance()
  .patch(`/api/v1/admin/ground-element`, data);