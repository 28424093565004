import getInstance from './instance';
export { upload } from "./file"; 

export const getList = options => getInstance()
  .get('/api/v1/admin/markers/', {
    params: options
  });
export const getItem = id => getInstance()
  .get(`/api/v1/admin/markers/${id}`);
export const update = (item, id, params) => getInstance()
  .patch(`/api/v1/admin/markers/${id}`, item, { params });
export const create = (item, params) => getInstance()
  .post('/api/v1/admin/markers/', item, { params });
export const remove = id => getInstance()
  .delete(`/api/v1/admin/markers/${id}`);