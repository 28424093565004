import useReactRouter from 'use-react-router';
import { useState, useEffect, useCallback } from "react";

export default function useGetDetail(container) {
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(false);
  const { match: { params: { id }}} = useReactRouter();

  const loadiItem = useCallback(async () => {
    setLoading(true);
    try {
      const loadedItem = await container.getDetail(id);
      setItem(loadedItem);
    } finally {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (id) {
      loadiItem();
    }
  }, [id]);

  return { item, loading };
}