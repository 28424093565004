import React, { useState, useCallback, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { contentTypes } from '../../constants';
import { inject } from 'mobx-react';
import DefaultContentDetail from './DefaultContentDetail';
import NoItems from '../../Components/NoItems';
import BasePapper from '../../Components/BasePapper';
import TextContentDetail from './TextContentDetail';
import ModelContentDetail from './ModelContentDetail';
import BundleContentDetail from '././BundleContentDetail';
import useRouter from 'use-react-router';
import addCampaignIdToRoute from '../../helpers/addCampaignIdToRoute';

export const accepts = {
  [contentTypes.MODEL]: ".obj",
  [contentTypes.IMAGE]: ".jpg, .jpeg, .png",
  [contentTypes.TVIDEO]: ".mp4, .webm",
  [contentTypes.VIDEO]: ".mp4, .webm",
  [contentTypes.RTMP]: "*",
  [contentTypes.BUNDLE]: "*",
}

const contentDetailsComponents = {
  [contentTypes.IMAGE]: DefaultContentDetail,
  [contentTypes.VIDEO]: DefaultContentDetail,
  [contentTypes.TVIDEO]: DefaultContentDetail,
  [contentTypes.TEXT]: TextContentDetail,
  [contentTypes.RTMP]: TextContentDetail,
  [contentTypes.MODEL]: ModelContentDetail,
  [contentTypes.BUNDLE]: BundleContentDetail,
}

export const contentTypesAlias = {
  [contentTypes.IMAGE]: 'Image',
  [contentTypes.RTMP]: 'Rtmp',
  [contentTypes.VIDEO]: 'Video',
  [contentTypes.TVIDEO]: 'TVideo',
  [contentTypes.MODEL]: 'Model',
  [contentTypes.TEXT]: 'Text',
  [contentTypes.BUNDLE]: 'Bundle',
}

const ContentWrapper = ({ content, match }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(contentTypes.MODEL);
  const initContainer = contentDetailsComponents[type];
  const [item, setItem] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const [{ Container }, setContainer] = useState({ Container: initContainer });
  const { params: { id } } = match;
  
  const loadItem = async () => {
    setLoading(true);
    try {
      const item = await content.getDetail(id);
      setItem(item);
      setType(item.type);
      setContainer({ Container: contentDetailsComponents[item.type] })
    } finally {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    if (id) {
      loadItem();
    }
  }, [id]);

  const handleTypeChanged = useCallback(({ target: { value }}) => {
    setType(value);
  }, []);
  const { campaignId } = useRouter().match.params;
  const handleSubmit = useCallback(async (data) => {
    try {
      setLoading(true)
      if (id) {
        await content.update(item, data, { spaceId: campaignId });
        setItem(item);
      } else {
        const item = await content.create(data, { spaceId: campaignId })
        if (!item) {
          return;
        }
        const { id } = item;
        const redirect = addCampaignIdToRoute(`/content/${id}`, campaignId);
        setRedirectTo(redirect);
      }
    } finally {
      setLoading(false)
    }
    
  }, [type, item, content, id])

  useEffect(() => {
    setContainer({ Container: contentDetailsComponents[type] })
  }, [type])


  if (redirectTo) {
    return <Redirect to={redirectTo}/>
  }

  return (
    <BasePapper>
      { id && !item ? (
        <NoItems>Loading...</NoItems>
      ) : (
        <Container
          type={type}
          onTypeChanged={handleTypeChanged}
          item={item}
          fileAccept={accepts[type]}
          onSubmit={handleSubmit}
          loading={loading}
        />
      )}
    </BasePapper>
  );
};

export default inject('content')(
  withRouter(ContentWrapper)
);