import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getFileName } from '../helpers/fileNameSplitter';

const styles = theme => ({
  root: {
    display: 'relative',
  },
  progress: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%'
  }
})

class FileListItem extends PureComponent {
  handleDelete = e => {
    const { onDelete, index } = this.props;
    onDelete(index);
  }
  render() {
    const { onDelete, classes, file, index, ...rest } = this.props;
    return (
      <ListItem className={classes.root}>
        <ListItemText
          primary={getFileName(file.fileName)}
          {...rest}
        />
        { file.loading && (
          <LinearProgress
            className={classes.progress}
          />
        )}
        <ListItemSecondaryAction>
          <IconButton onClick={this.handleDelete}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles)(FileListItem);