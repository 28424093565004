export default function ValidationError(componentErrors) {
  Error.call(this, componentErrors);
  this.name = "ValidationError";
  this.componentErrors = componentErrors;
  this.message = 'Validation error';

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, ValidationError);
  } else {
    this.stack = (new Error()).stack;
  }
}

ValidationError.prototype = Object.create(Error.prototype);