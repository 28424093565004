import ModelWithFiles from "../base/ModelWithFiles";

export default class ModelContentModel extends ModelWithFiles {

  mapProps({
    text,
    thumbnail,
    type,
    _id,
    name,
    modelFiles,
  }) {
    if (modelFiles) {
      modelFiles.scale = parseFloat(modelFiles.scale);
      if (this.modelFiles) {
        modelFiles.obj = modelFiles.obj || this.modelFiles.obj;
      }
    }
    const newPropsObject = {
      thumbnail: thumbnail || this.thumbnail,
      id: _id || this.id,
      text: text || this.text,
      type,
      name,
      modelFiles: modelFiles || this.modelFiles,
    }
  
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      text,
      thumbnail,
      type,
      name,
      modelFiles,
    } = this;
    return {
      text,
      thumbnail,
      type,
      name,
      modelFiles,
    };
  }

  getFilesToUpload() {
    const files = {
      thumbnail: this.thumbnail,
    }
    if (this.modelFiles) {
      files.obj = this.modelFiles.obj
    }
    return files;
  }

  setFilenames({ thumbnail, obj }) {
    this.thumbnail = thumbnail
    if (this.modelFiles) {
      this.modelFiles.obj = obj;
    }
  }
}
