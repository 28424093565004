import getInstance from "./instance";

export const getList = options => getInstance()
  .get('/api/v1/admin/users', {
    params: options
  });
export const getItem = id => getInstance()
  .get(`/api/v1/admin/users/${id}`);
export const update = (item, id) => getInstance()
  .patch(`/api/v1/admin/users/${id}`, item);
export const create = item => getInstance()
  .post('/api/v1/admin/users/', item);
export const remove = id => getInstance()
  .delete(`/api/v1/admin/users/${id}`);