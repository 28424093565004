import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import getRoutes from '../../routes/clientRouter';
import superRoutes from '../../routes/superRouter';
import CustomDrawer from './CustomDrawer';
import { inject, observer } from 'mobx-react';
import SwitchRenderer from './SwitchRenderer';

const drawerWidth = 240;

// const routes = superRoutes;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  widthClass: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 0,
    padding: theme.spacing.unit * 3,
    boxSizing: 'border-box',
    width: `calc(100% - ${drawerWidth}px)`
  },
  toolbar: theme.mixins.toolbar,
});

const Dashboard = ({ classes, session, match }) => {
  const clientRoutes = useMemo(() => getRoutes())
  return (
    <div className={classes.root}>
      <CustomDrawer
        routes={session.isSuper ? superRoutes : clientRoutes}
        widthClass={classes.widthClass}
      />
      <main className={classes.content}>
        {session.isSuper ? (
          <>
            <SwitchRenderer routes={superRoutes} />
          </>
        ) : (
            <SwitchRenderer routes={clientRoutes} />
          )}
      </main>
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(
    inject('session')(
      observer(Dashboard)
    )
  )
);