import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing.unit * 9,
    right: theme.spacing.unit * 9,
    margin: 'auto',
    "&:hover, &:focus, &:active": {
      color: "white"
    }
  }
})

const AddButton = ({classes, ...props}) => {
  return (
    <Fab
      {...props}
      classes={classes}
    >
      <AddIcon/>
    </Fab>
  );
};

export default withStyles(styles)(AddButton);