import React from 'react';
import FileInput from '../../Components/FileInput';

const Thumbnail = ({
  preview,
  fileName,
  onChange,
  errors: error,
  text="Upload thumbnail"
}) => {
  return (
    <>
      {preview && (
        <img
          style={{ maxWidth: '100%' }}
          src={preview}
          alt={fileName}
        />
      )}
      <FileInput
        fileName={fileName}
        onChange={onChange}
        name="thumbnail"
        inputProps={{
          accept: ".jpg,.png,.jpeg"
        }}
        buttonText={text}
        error={!!error}
        errorText={error}
      />
    </>
  );
};

export default Thumbnail;