import React from 'react';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FileInput from '../../Components/FileInput';
import Grid from '@material-ui/core/Grid';

import CustomToolbar from '../../Components/CustomToolbar';
import CustomLinearProgress from '../../Components/CustomLinearProgress';
import PictureElementDetailContainer from './container';

const styles = theme => ({
  form: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  fileInput: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  image: {
    maxWidth: '100%',
  },
  imageBg: {
    display: 'inline-block',
    padding: theme.spacing.unit * 2,
    backgroundColor: 'darkgray'
  }
})

let PictureElementDetail = observer(({
  onChange,
  onSubmit,
  classes,
  errors,
  loading,
  name,
  imageFileName,
  imageUrl,
}) => {
  return (
    <Paper>
      { loading && <CustomLinearProgress/> }
      <CustomToolbar>
        {name}
      </CustomToolbar>
      <form onSubmit={onSubmit} className={classes.form} autoComplete="off">
        <Grid container>
          <Grid item xs={7}>
            { imageUrl && (
              <div>
                <div className={classes.imageBg}>
                  <img src={imageUrl} alt="" className={classes.image}/>
                </div>
              </div>
            )}
            <FileInput
              name="image"
              fileName={imageFileName}
              onChange={onChange}
              errorText={errors.image}
              error={errors.image}
              inputProps={{
                accept: "image/jpeg,image/png"
              }}
              buttonText="Change Image"
              className={classes.fileInput}
            />
          </Grid>
        </Grid>
        
        <Button
          color="secondary"
          disabled={loading}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </form>
    </Paper>
  );
});

PictureElementDetail = withStyles(styles)(PictureElementDetail);

export default PictureElementDetailContainer('pictureDesignElements')(
  PictureElementDetail
);