import React, { useCallback, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CustomToolbar from '../../Components/CustomToolbar';
import { contentTypes, maxFileLength, texturesAccept, materialsAccept, } from '../../constants';
import {  useFile, useImageFile, useFilesList } from './hooks';
import Thumbnail from './Thumbnail';
import FileInput from '../../Components/FileInput';
import { maxLengthValidation, combineValidators, fileIsRequiredValidation, floatValidation } from '../../validators/validators';
import FadedProgress from '../../Components/FadedProgress';
import { contentTypesAlias } from './ContentWrapper';
import FileListTitle from '../../Components/FileListTitle';
import FilesList from '../../Components/FilesList';
import { upload } from '../../api/file';
import { withSnackbar } from 'notistack';
import useTextState from '../../hooks/useTextState';

const styles = theme => ({
  nameInput: {
    marginRight: theme.spacing.unit * 2,
  },
  sizeInput: {
    marginTop: theme.spacing.unit * 2,
  },
  typeSelect: {
    minWidth: 120,
    textTransform: 'capitalize',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  fileInput: {
    marginTop: theme.spacing.unit * 2,
  },
});

const maxLengthValidator = maxLengthValidation(maxFileLength, 'Maximum file scale is 1 GB!');

const fileValidator = combineValidators(
  fileIsRequiredValidation(),
  maxLengthValidator,
);

const floatValidator = floatValidation();

function ModeltContentDetail({
  loading,
  onSubmit,
  item,
  type,
  onTypeChanged,
  classes,
  fileAccept,
  enqueueSnackbar
}) {
  const {
    state: { name, scale },
    onChange,
    errors,
    validate: validateTextState
  } = useTextState({
    name: (item && item.name) || '',
    scale: (item && item.modelFiles && item.modelFiles.scale) || 0,
  }, { scale: floatValidator });

  const modelFile = useFile(
    item && item.modelFiles && item.modelFiles.obj,
    fileValidator
  );
  const thumbnail = useImageFile(
    item &&  item.thumbnail,
    maxLengthValidator
  );

  const materials = useFilesList(
    item && item.modelFiles && item.modelFiles.materials,
    upload,
    enqueueSnackbar
  );
  const textures = useFilesList(
    item && item.modelFiles && item.modelFiles.textures,
    upload,
    enqueueSnackbar
  );

  const disableSubmitLoading = useMemo(() => {
    return materials.loading || textures.loading || loading
  }
  , [materials.loading, textures.loading, loading]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    let isValid = ![modelFile.validate(), thumbnail.validate(), validateTextState()]
      .some(error => !!error);
    if (isValid) {
      onSubmit({
        name,
        modelFiles: {
          obj: modelFile.file,
          scale,
          materials: materials.fileNames,
          textures: textures.fileNames,
        },
        thumbnail: thumbnail.file,
        type
      });
    }
  }, [onSubmit, name, modelFile.file, thumbnail.file, scale, materials.fileNames, textures.fileNames, type])
  return (
    <>
      <FadedProgress
        loading={loading}
      />
      <CustomToolbar disableGutters>
        Content
      </CustomToolbar>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={16}>
          <Grid item xs={7}>
            <div>
              <TextField
                name="name"
                value={name}
                onChange={onChange}
                label="Name"
                className={classes.nameInput}
                required
              />
              <TextField
                select
                value={type}
                className={classes.typeSelect}
                name="type"
                onChange={onTypeChanged}
                label="Type"
              >
                {Object.values(contentTypes).map(type => (
                  <MenuItem
                    key={type}
                    value={type}
                    className={classes.menuItem}
                  >
                    {contentTypesAlias[type]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                value={scale}
                className={classes.sizeInput}
                name="scale"
                onChange={onChange}
                label="Scale"
                required
                fullWidth
                helperText={errors.scale}
                error={!!errors.scale}
              />
              <FileListTitle
                text="Textures"
                onChange={textures.onChange}
                fileAccept={texturesAccept}
              />
              {textures.files.length !== 0 && (
                <FilesList
                  files={textures.files}
                  onDelete={textures.onDelete}
                />
              )}

              <FileListTitle
                text="Materials"
                onChange={materials.onChange}
                fileAccept={materialsAccept}
              />
              {materials.files.length !== 0 && (
                <FilesList
                  files={materials.files}
                  onDelete={materials.onDelete}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={5}>
            <Thumbnail
              fileName={thumbnail.fileName}
              preview={thumbnail.preview}
              onChange={thumbnail.onChange}
            />
            <FileInput
              fileName={modelFile.fileName}
              onChange={modelFile.onChange}
              name="model"
              errorText={modelFile.error}
              error={!!modelFile.error}
              inputProps={{
                accept: fileAccept
              }}
              buttonText="Upload Model"
              helperText="Maximum file scale 1 GB"
              className={classes.fileInput}
            />
          </Grid>
        </Grid>
        <Button
          color="secondary"
          disabled={disableSubmitLoading}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </form>
    </>
  )
}

export default withStyles(styles)(
  withSnackbar(ModeltContentDetail)
)