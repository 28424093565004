import React, { useMemo, useEffect } from 'react';
import SwitchRenderer from '../Dashboard/SwitchRenderer';
import useRouter from 'use-react-router';
import getRoutes from '../../routes/clientRouter';
import { useSelectedCampaign } from '../../hooks/useStores';

const SuperCampaign = () => {
  const { match: { params: { campaignId }}} = useRouter();
  const campaignRoutes = useMemo(() => getRoutes(campaignId), [campaignId]);
  const selectedCamapin = useSelectedCampaign();
  useEffect(() => {
    selectedCamapin.setCampaignId(campaignId);
    return () => selectedCamapin.setCampaignId(undefined);
  }, [campaignId])
  return (
    <SwitchRenderer
      routes={campaignRoutes}
    />
  );
};

export default SuperCampaign;