import BaseModelsContainer from '../base/BaseModelsContainer';
import { designElementsTypes } from '../../constants';

const filters = {
  [designElementsTypes.TEXT]: ({ type }) => type === 'text' || type === 'number',
  [designElementsTypes.MESSAGE]: ({ type }) => type === designElementsTypes.MESSAGE,
  [designElementsTypes.PICTURE]: ({ type }) => type === designElementsTypes.PICTURE,
}

export default class DesignElementsContainer extends BaseModelsContainer {
  constructor(Model, netService, notifier, type) {
    super(Model, netService, notifier);
    this.type = type;
  }
  async loadList(options) {
    const data = await this.netService.getList(options);
    const filter = filters[this.type];
    const list = data.items.filter(filter)
      .map(item => new this.Model(item)); 
    this.list = list;
  }
}