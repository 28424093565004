import React from 'react';
import CustomLinearProgress from './CustomLinearProgress';
import Fade from '@material-ui/core/Fade';

const FadedProgress = ({ loading }) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '800ms' : '0ms',
      }}
      unmountOnExit>
      <CustomLinearProgress />
    </Fade>
  );
};

export default FadedProgress;