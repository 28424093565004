import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';

export default containerName => Wrapped => {

  class TextDesignElementsWrapper extends PureComponent {
    async loadData(options={}) {
      const { campaignId } = this.props.match.params;
      if (campaignId) {
        options.spaceId = campaignId
      }
      this.setState({ loading: true });
      try {
        await this.props[containerName].loadList(options);
      }
      finally {
        this.setState({ loading: false })
      }
    }
  
    componentDidMount() {
      this.loadData();
    }

    render() {
      return (
        <Wrapped
          {...this.state}
          {...this.props}
          onPageChange={this.handlePageChange}
          onSearchChange={this.handleSearchChange}
          container={this.props[containerName]}
        />
      );
    }
  }


  return inject(containerName)(
    withRouter(TextDesignElementsWrapper)
  );
}

