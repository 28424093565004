import BaseModel from "./BaseModel";

export default class ModelWithFiles extends BaseModel {

  getFilesToUpload() {
  }

  setFilenames(filenames) {
    Object.assign(this, filenames);
  }

  clearFiles() {
    this._files = {}
  }
}