import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import Pagination from "material-ui-flat-pagination";

const styles = () => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
})

const CustomPagination = ({
  classes: { pagination, ...classes },
  ...rest,
}) => {
  return (
    <Pagination
      classes={{
        root: pagination,
        ...classes,
      }}
      {...rest}
    />
  );
};

export default withStyles(styles)(CustomPagination);