import { useState, useEffect, useCallback } from 'react';
import useDebounce from './useDebounce';

export default function usePaginatedContainer(container) {
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  const loadItems = useCallback(async () => {
    setLoading(true);
    try{
      await container.loadList({ search: debouncedSearch, offset });
    } finally {
      setLoading(false);
    }
  }, [container, setLoading, debouncedSearch] )

  useEffect(() => {
    loadItems();
  }, [container, offset, debouncedSearch]);

  const onSearchChange = useCallback(({ target: { value }}) => {
    setSearch(value);
  }, [setSearch]);

  const onPageChange = useCallback((e, newOffset) => {
    setOffset(newOffset);
  }, [setOffset]);

  return { offset, search, onSearchChange, onPageChange, loading }
}