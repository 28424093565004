import { decorate, observable, action } from 'mobx';

class SelectedCampaign {
  campaignId = '';

  setCampaignId(id) {
    this.campaignId = id;
  }
}

decorate(SelectedCampaign, {
  campaignId: observable,
  setCampaignId: action,
});

export default SelectedCampaign;