import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2
  }
});

const SubmitButton = (props) => {
  return (
    <Button
      color="secondary"
      type="submit"
      variant="contained"
      {...props}
    />
  );
};

export default withStyles(styles)(SubmitButton);