import React, { useCallback, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import BasePapper from '../../Components/BasePapper';
import FadedProgress from '../../Components/FadedProgress';
import CustomToolbar from '../../Components/CustomToolbar';
import { useClients, useCampaigns } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import useTextState from '../../hooks/useTextState';
import { withStyles } from '@material-ui/core';
import useLoadOptions from '../../hooks/useLoadOptions';
import SelectAutocomplete from '../../Components/SelectAutocomplete';
import { permissionsValidator } from './validators';
import {
  fieldIsRequired,
  minLengthValidator,
  combineValidators
} from '../../validators/validators';

const styles = theme => ({
  form: {
    maxWidth: '50%',
  },
  input: {
    marginTop: theme.spacing.unit * 2,
  },
});

const fieldRquiredValidator = permissionsValidator('Permissions is required');
const minLengthPasswordValidator = minLengthValidator(8, 'The minimum password length is 8');
const passwordValidator = combineValidators(
  fieldIsRequired('Password is required'),
  minLengthPasswordValidator
);

const ClientDetail = observer(({
  classes,
}) => {
  const clients = useClients();
  const {
    state: {
      login,
      password,
      isSuper,
      permissions,
    },
    onChange,
    errors,
    validate,
  } = useTextState({
    login: "",
    password: "",
    isSuper: false,
    permissions: "",
  }, {
    permissions: fieldRquiredValidator,
    password: passwordValidator,
  });
  const campaigns = useCampaigns();

  const appOptions = useLoadOptions(campaigns);
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirect] = useState();

  const handleAppValue = useCallback((value) => {
    onChange({ target: { value, name: 'permissions' } });
  }, [onChange]);

  useEffect(() => {
    if (isSuper) {
      handleAppValue('');
    }
  }, [isSuper]);

  const handleSubmit = useCallback(async(e) => {
    e.preventDefault();
    const data = {
      login,
      password,
      isSuper,
    };
    if (!isSuper) {
      data.permissions = permissions.key
    }
    const isValid = !validate();
    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
      const item = await clients.create(data);
      if (item) {
        setRedirect('/clients');
      }
    } finally {
      setLoading(false);
    }
  }, [login, password, isSuper, permissions, clients]);

  if (redirectTo) {
    return <Redirect to={redirectTo}/>
  }

  return (
    <BasePapper>
      <FadedProgress
        loading={loading}
      />
      <CustomToolbar disableGutters>
        Client
      </CustomToolbar>
      <form
        onSubmit={handleSubmit}
        className={classes.form}
        autoComplete="off"
      >
        <TextField
          name="login"
          value={login}
          onChange={onChange}
          label="Login"
          className={classes.input}
          fullWidth
          required
        />
        <TextField
          name="password"
          value={password}
          onChange={onChange}
          type="password"
          label="Password"
          className={classes.input}
          fullWidth
          error={!!errors.password}
          helperText={errors.password}
        />
        <FormControlLabel
          className={classes.input}
          label="Is Superadmin"
          control={
            <Switch
              name="isSuper"
              value="isSuper"
              onChange={onChange}

              className={classes.nameInput}
              checked={isSuper}
            />
          }
        />
        <SelectAutocomplete
          onInputChange={appOptions.handleInputChanged}
          placeholder="Select Campaign"
          value={permissions}
          onChange={handleAppValue}
          cacheOptions
          options={appOptions.options}
          textFieldProps={{
            label: 'Campaign',
            InputLabelProps: {
              shrink: true,
            },
            error: !!errors.permissions,
            helperText: errors.permissions,
          }}
          disabled={isSuper}
          name="Campaign"
          className={classes.input}
        />
        <div>
          <Button
            className={classes.input}
            color="secondary"
            disabled={loading}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </BasePapper>
  );
});

export default withStyles(styles)(ClientDetail);