import React, { PureComponent } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
class ListItemLink extends PureComponent {
  renderLink = itemProps => <Link to={this.props.to} {...itemProps} />;

  render() {
    const { icon: Icon, text, classes = {} } = this.props;
    return (
      <li>
        <ListItem button component={this.renderLink}>
          {Icon && (
            <ListItemIcon>
              <Icon
                classes={{
                  colorPrimary: classes.text
                }}
                color="primary"
              />
            </ListItemIcon>
          )}
          <ListItemText
            classes={{
              primary: classes.text
            }}
            primary={text}
          />
        </ListItem>
      </li>
    );
  }
}

export default ListItemLink;