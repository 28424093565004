import { useState, useCallback, useEffect, useMemo } from 'react';
import { getFileName } from '../../helpers/fileNameSplitter';
import { maxFileLength } from '../../constants';

export function useFile(defaultFileName = '', validator) {
  if (defaultFileName instanceof File) {
    defaultFileName = defaultFileName.name;
  }
  
  const [_fileName, setFileName] = useState(getFileName(defaultFileName));
  const [error, setError] = useState(false);
  const [{ _localFile }, setFile] = useState({ _localFile: null });

  const validate = useCallback(({ fileName = _fileName, file = _localFile } = {}) => {
    const error = validator({ file, fileName });
    if (error) {
      setError(error);
      return error;
    }
  }, [validator, setError, _fileName, _localFile])

  const fileChanged = useCallback(({ target: { files } }) => {
    const [file] = files;
    if (validator) {
      validate({ file });
    }
    setFile({ _localFile: file });
    setFileName(file.name);
    setError(false);
  }, []);

  return { fileName: _fileName, onChange: fileChanged, file: _localFile, error, validate }
}

export function useImageFile(defaultFileName = '', validate) {
  const file = useFile(defaultFileName, validate);
  const [preview, setPreview] = useState(defaultFileName)
    ;
  useEffect(() => {
    ;
    if (file.file) {
      setPreview(URL.createObjectURL(file.file))
    } else {
      setPreview(defaultFileName)
    }
    return () => {
      URL.revokeObjectURL(preview);
    };
  }, [file.file])

  return { ...file, preview }
}

export function useFilesList(initFilenames = [], upload, enqueueSnackbar) {
  const initFiles = initFilenames.map(fileName => ({ fileName, loading: false }));
  const [files, setFiles] = useState(initFiles);
  const [inLoading, setInLoading] = useState([]);
  const [loadedFiles, setLoadedFiles] = useState([]);
  const fileNames = useMemo(() => files.map(({ fileName }) => fileName), [files]);
  const loading = useMemo(() => files.some(({ loading }) => loading), [files]);

  const onAdded = useCallback((e, newFiles) => {
    const tmpFiles = [...files];
    newFiles.forEach(({ name }) => {
      tmpFiles.push(({ fileName: name, loading: true }));
    });
    setFiles(tmpFiles);
  }, [setFiles, files]);

  const onDelete = useCallback(index => {
    let tmpFiles = [...files];
    tmpFiles.splice(index, 1);
    setFiles(tmpFiles);  
  }, [setFiles, files]);

  const onFileLoaded = useCallback(({ fileName, fileNameFromServer }) => {
    const tmpFiles = [...files];
    const itemIndex = tmpFiles.findIndex(item => item.fileName === fileName);
    if (itemIndex === -1) {
      return;
    }
    tmpFiles[itemIndex] = {
      fileName: fileNameFromServer,
      loading: false,
    }
    setFiles(tmpFiles);
  }, [setFiles, files]);

  const onChange = useCallback(e => {
    const { files } = e.target;
    if (files.length === 0) {
      return;
    }
    const filesToSend = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxFileLength) {
        enqueueSnackbar('Maximum file size is 30 MB!', {
          variant: 'error'
        });
        return;
      }
      filesToSend.push(file);
    }
    setInLoading(filesToSend);
  }, [files, onAdded, onFileLoaded]);

  useEffect(() => {
    if (inLoading.length) {
      setFiles([...files, ...inLoading.map(file => ({ fileName: file.name, loading: true }))]);
      inLoading.forEach(async file => {
        const fileNameFromServer =  await upload(file);
        setLoadedFiles([...loadedFiles, { fileNameFromServer, fileName: file.name }]);
      });
      setInLoading([]);
    }
  }, [inLoading, setFiles, files]);

  useEffect(() => {
    if (loadedFiles.length) {
      loadedFiles.forEach(file => {
        onFileLoaded(file);
      });
      setLoadedFiles([]);
    }
  }, [loadedFiles, onFileLoaded])

  return { files, fileNames, onAdded, onDelete, onFileLoaded, onChange, loading };
}

