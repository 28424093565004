import ModelWithFiles from "../base/ModelWithFiles";


export default class TextContentModel extends ModelWithFiles {

  mapProps({
    text,
    thumbnail,
    type,
    _id,
    name,
  }) {
    
    const newPropsObject = {
      thumbnail: thumbnail || this.thumbnail,
      id: _id || this.id,
      text: text || this.text,
      type,
      name,
    }
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      text,
      thumbnail,
      type,
      name,
    } = this;
    return {
      text,
      thumbnail,
      type,
      name,
    };
  }

  getFilesToUpload() {
    const files = {
      thumbnail: this.thumbnail,
    }
    return files;
  }

  setFilenames({ thumbnail }) {
    this.thumbnail = thumbnail
  }
}
