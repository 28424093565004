import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import stores from './store';
import { setErrorHandler } from './api/instance';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#001e51'
    },
    chartStroke: grey[400],
    chartFill: blue[700]
  },
  typography: {
    useNextVariants: true,
  },
  visuallyHidden: {
    position: 'absolute !important',
    clip: 'rect(1px, 1px, 1px, 1px)',
    padding: '0 !important',
    border: '0 !important',
    height: '1px !important',
    width: '1px !important',
    overflow: 'hidden',
  }
});

setErrorHandler(error => {
  const { request } = error;
  if(request && request.status === 500) {
    stores.notifier.enqueueSnackbar("Unexpected server error", {
      variant: "error"
    })
  }
  // throw error;
  return Promise.reject(error);
})

ReactDOM.render(<Root theme={theme} stores={stores} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
