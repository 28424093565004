import axios from "axios";
import authStorage from "../helpers/authStorage";

const END_POINT = "/";

let _instance;
let lastToken;
let errorHandler;

export default function getInstance() {
  let token = authStorage.getToken();
  if (!_instance || (token && token !== lastToken)) {
    _instance = createInstance(token);
  }
  return _instance;
}

function createInstance(token) {
  const options = {
    baseURL: END_POINT,
  };
  if (token) {
    options.headers = { AUTHORIZATION: `Bearer ${token}` };
    lastToken = token;
  }
  let instance = axios.create(options);
  if (errorHandler) {
    instance.interceptors.response.use(resp => resp, errorHandler);
  }
  return instance;
};

export function setErrorHandler(handler) {
  errorHandler = handler;
  createInstance(lastToken);
}