import React from 'react';
import { observer } from 'mobx-react';
import Paper from '@material-ui/core/Paper';
import NoItems from '../../Components/NoItems';
import CustomToolbar from '../../Components/CustomToolbar';
import CustomLinearProgress from '../../Components/CustomLinearProgress';
import ListItemLink from '../../Components/ListItemLink';
import { List } from '@material-ui/core';
import TextDesignElementsContainer from "../TextDesignElements/container";
import useRouter from 'use-react-router';
import addCampaignIdToRoute from '../../helpers/addCampaignIdToRoute';

const PictureDesignElements = observer(({
  container,
  loading,
}) => {
  const { campaignId } = useRouter().match.params;
  return (
    <Paper>
      { loading && (
        <CustomLinearProgress/> 
      )}
      <CustomToolbar>
        Message Design Elements
      </CustomToolbar>
      { container.list.length !== 0 ? (
        <List>
          {container.list.map(item => (
            <ListItemLink
              to={addCampaignIdToRoute(`/picture-design-elements/${item.id}`, campaignId)}
              key={item.id}
              text={item.name}
            />
          ))}
        </List>
      ) : (
        <NoItems>
          { loading ? "Loading..." : "No items"}
        </NoItems>
      )}
    </Paper>
  );
});

export default TextDesignElementsContainer('pictureDesignElements')(
  PictureDesignElements
)