import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchToolbar from '../../Components/SearchToolbar';
import MarkersContainer from './MarkersContainer';
import MarkerListItem from './MarkerListItem';
import NoItems from '../../Components/NoItems';
import CustomPagination from '../../Components/CustomPagination';
import CustomLinearProgress from '../../Components/CustomLinearProgress';
import AddButton from '../../Components/AddButton';
import addCampaignIdToRoute from '../../helpers/addCampaignIdToRoute';

const styles = theme => ({
  grid: {
    padding: theme.spacing.unit * 2,
  },
  paginator: {
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
})

const MarkerList = observer(({
  markers,
  onDelete,
  classes,
  offset,
  onPageChange,
  limit,
  loading,
  onSearchChange,
  search,
  match
}) => {
  const { campaignId } = match.params;
  const addButtonLink = addCampaignIdToRoute('/marker', campaignId)
  return (
    <Paper>
      { loading && (
        <CustomLinearProgress/> 
      )}
      <SearchToolbar
        onChange={onSearchChange}
        search={search}
      >
        Markers
      </SearchToolbar>
      { markers.list.length !== 0 ? (
        <Grid className={classes.grid} container spacing={16}>
          {markers.list.map(item => (
            <Grid key={item.id} item xs={6}>
              <MarkerListItem 
                marker={item}
                onDelete={onDelete}
                campaignId={campaignId}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoItems>
          { loading ? "Loading..." : "No items"}
        </NoItems>
      )}
      <CustomPagination
        limit={limit}
        offset={offset}
        total={markers.count}
        className={classes.paginator}
        onClick={onPageChange}
      />
      <AddButton
        color="secondary"
        component={Link}
        to={addButtonLink}
      />
    </Paper>
  );
});

export default MarkersContainer(withStyles(styles)(MarkerList));