import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  fileInput: theme.visuallyHidden,
});

class FileInputButton extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  
  handleClick = e => {
    e.preventDefault();
    this.inputRef.current.click();
  }

  render() {
    const {
      classes,
      onChange,
      value,
      inputProps,
      name,
      labelProps,
      component: Component,
    } = this.props;

    return (
      <React.Fragment>
        <label
          {...labelProps}
        >
          <input
            ref={this.inputRef}
            className={classes.fileInput}
            type="file"
            onChange={onChange}
            value={value}
            name={name}
            { ...inputProps }
          />
          <Component
            onClick={this.handleClick}
          />
        </label>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FileInputButton);