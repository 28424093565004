import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import SearchToolbar from '../../Components/SearchToolbar';
import NoItems from '../../Components/NoItems';
import AddButton from '../../Components/AddButton';
import CustomPagination from '../../Components/CustomPagination';
import FadedProgress from '../../Components/FadedProgress';
import usePaginatedContainer from '../../hooks/usePaginatedContainer';
import { useCampaigns } from '../../hooks/useStores';
import useDeleteWithContainer from '../../hooks/useDeleteWithContainer';
import DeleteDialog from '../../Components/DeleteDialog';
import CampaignListItem from '../CampaignDetail/CampaignListItem';

const styles = theme => ({
  paginator: {
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  grid: {
    padding: theme.spacing.unit * 2,
  },
})

const Campaigns = observer(({
  classes,
}) => {
  const campaigns = useCampaigns();
  const appsPag = usePaginatedContainer(campaigns);
  const deleteDialog = useDeleteWithContainer(campaigns);
  return (
    <>
      <Paper>
        <FadedProgress loading={appsPag.loading} />
        <SearchToolbar
          onChange={appsPag.onSearchChange}
          search={appsPag.search}
        >
          Campaigns
      </SearchToolbar>
        {campaigns.list.length !== 0 ? (
          <List>
            {campaigns.list.map(item => (
              <CampaignListItem
                key={item.id}
                text={item.name}
                item={item}
                onDelete={deleteDialog.handleDelete}
                to={`/campaign/${item.id}`}
              />
            ))}
          </List>
        ) : (
            <NoItems>
              {appsPag.loading ? "Loading..." : "No items"}
            </NoItems>
          )}
        <CustomPagination
          limit={30}
          offset={appsPag.offset}
          total={campaigns.count}
          className={classes.paginator}
          onClick={appsPag.onPageChange}
        />
        <AddButton
          color="secondary"
          component={Link}
          to="/campaign-detail"
        />
      </Paper>
      <DeleteDialog
        open={deleteDialog.open}
        onDelete={deleteDialog.handleConfirm}
        onClose={deleteDialog.handleClose}
        onExit={deleteDialog.handleExit}
        description={`${deleteDialog.item ? deleteDialog.item.name : ''}`}
        loading={deleteDialog.loading}
      />
    </>
  );
});

export default withStyles(styles)(Campaigns);