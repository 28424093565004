import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  smallCell: {
    width: '1%',
    whiteSpace: 'nowrap',
    padding: `0 ${theme.spacing.unit}px`,
  }
})

const PhotosAndVideos = ({ classes, data }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.smallCell}>
          </TableCell>
          <TableCell align="center">
            Photos
          </TableCell>
          <TableCell align="center">
            Videos
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell variant="head" className={classes.smallCell}>
            Taken
          </TableCell>
          <TableCell align="center">
            {data.photosTaken}
          </TableCell>
          <TableCell align="center">
            {data.videosRecorded}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.smallCell}>
            Saved
          </TableCell>
          <TableCell align="center">
            {data.photosSaved}
          </TableCell>
          <TableCell align="center">
            {data.videosSaved}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.smallCell}>
            Shared
          </TableCell>
          <TableCell align="center">
            {data.photosShared}
          </TableCell>
          <TableCell align="center">
            {data.videosShared}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(PhotosAndVideos);