import React from 'react';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import CustomToolbar from '../../Components/CustomToolbar';
import CustomLinearProgress from '../../Components/CustomLinearProgress';
import TextElementDetailContainer from './container';

const styles = theme => ({
  form: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  textInput: {
    marginBottom: theme.spacing.unit * 2,
  }
})

const textElementDetail = observer(({
  onChange,
  onSubmit,
  classes,
  errors,
  loading,
  name,
  text,
}) => {
  return (
    <Paper>
      { loading && <CustomLinearProgress/> }
      <CustomToolbar>
        {name}
      </CustomToolbar>
      <form onSubmit={onSubmit} className={classes.form} autoComplete="off">
        <TextField
          name="text"
          value={text}
          onChange={onChange}
          label="Text"
          className={classes.textInput}
          required
          fullWidth
          error={errors.text}
          helperText={errors.text}
        />
        <Button
          color="secondary"
          disabled={loading}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </form>
    </Paper>
  );
});

export const TextElementDetail = withStyles(styles)(textElementDetail);

export default TextElementDetailContainer('textDesignElements')(
  TextElementDetail
);