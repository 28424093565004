import BaseModel from "../base/BaseModel";

export default class CampaignModel extends BaseModel {

  mapProps({
    _id,
    name,
  }) {
    const newPropsObject = {
      id: _id || this.id,
      name,
    }
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      name,
    } = this;
    return {
      name,
    };
  }
}
