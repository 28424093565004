import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useSession } from '../../hooks/useStores';

const styles = theme => ({
  positioner: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.unit * 2
  },
  avatar: {
    marginRight: theme.spacing.unit * 2,
    backgroundColor: theme.palette.primary.main
  },
})

const DrawerHeader = observer(({ classes }) => {
  const session = useSession();
  return (
    <div>
      <div className={classes.positioner}>
        <Avatar
          className={classes.avatar}
        >A</Avatar>
        <Typography
          className={classes.text}
          variant="h6"
          component="h1"
          noWrap
          color="inherit"
        >
          {session.login}
        </Typography>
      </div>
      <Divider/>
    </div>
  );
});

export default withStyles(styles)(DrawerHeader);