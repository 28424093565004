import PaginatedModelContainer from '../base/PaginatedModelContainer';
import { contentTypes } from '../../constants';
import BundleContentModel from './BundleContentModel';
import DefaultContentModel from './DefaultContentModel';
import ModelContentModel from './ModelContentModel';
import TextContentModel from './TextContentModel';

const models = {
  [contentTypes.BUNDLE]: BundleContentModel,
  [contentTypes.IMAGE]: DefaultContentModel,
  [contentTypes.MODEL]: ModelContentModel,
  [contentTypes.RTMP]: TextContentModel,
  [contentTypes.TEXT]: TextContentModel,
  [contentTypes.TVIDEO]: DefaultContentModel,
  [contentTypes.VIDEO]: DefaultContentModel,
}

export function getModelByType(type) {
  return models[type]
}

export default class ContentModelsContainer extends PaginatedModelContainer {
  
  async loadList(options={}) {
    const { offset=0, limit=15, search, ...rest } = options;
    const { items, count } = await this.netService
      .getList({ skip: offset * limit, limit, searchstring: search, ...rest });
    this.list = items.map(item => {
      const Model = getModelByType(item.type);
      return new Model(item);
    });
    this.count = Math.ceil(count / limit);
  }

  async create(data, options) {
    const Model = getModelByType(data.type);

    const item = new Model(data);
    try {
      const response = await this.netService.create(item, options);
      this.notifier.showSuccessMessage('Successfully saved');
      item.mapProps(response);
      this.list.push(response);
      return item;
    }
    catch(e) {
      if (e.response && e.response.status === 409) {
        this.notifier.showErrorMessage('Name must be unique');
      } else {
        throw e;
      }
    }
  }
  
  async update(item, data, options) {
    item.mapProps(data);
    try {
      const response = await this.netService.update(item, options);
      this.notifier.showSuccessMessage('Successfully saved');
      item.mapProps(response);
    } catch(e) {
      if (e.response && e.response.status === 409) {
        this.notifier.showErrorMessage('Name must be unique');
      } else {
        throw e;
      }
    }
  }

}