import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Link } from 'react-router-dom';


const LinkItemWithActions = ({
  icon: Icon,
  text,
  classes = {},
  to,
  children
}) => {
  return (
    <ListItem button component={Link} to={to}>
      {Icon && (
        <ListItemIcon>
          <Icon
            classes={{
              colorPrimary: classes.text
            }}
            color="primary"
          />
        </ListItemIcon>
      )}
      <ListItemText
        classes={{
          primary: classes.text
        }}
        primary={text}
      />
      <ListItemSecondaryAction>
        {children}
      </ListItemSecondaryAction>
    </ListItem>
  );
}


export default LinkItemWithActions;