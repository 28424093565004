import ModelWithFiles from "../base/ModelWithFiles";
import { contentTypes, maxFileLength } from "../../constants";
import ValidationError from "../base/ValidationError";

export default class PointsUploadViewModel extends ModelWithFiles {

  mapProps({
    fileName,
    text,
    thumbnail,
    type,
    _id,
    name,
    modelFiles,
    fileNameAndroid,
    fileNameIos
  }) {
    if (modelFiles) {
      modelFiles.scale = parseFloat(modelFiles.scale);
      if (this.modelFiles) {
        modelFiles.obj = modelFiles.obj || this.modelFiles.obj;
      }
    }
    const newPropsObject = {
      fileName: fileName || this.fileName,
      thumbnail: thumbnail || this.thumbnail,
      id: _id || this.id,
      text: text || this.text,
      type,
      name,
    }
    if (this.type !== type) {
      newPropsObject.fileName = fileName;
    }
    if (type === contentTypes.MODEL) {
      newPropsObject.modelFiles = modelFiles || this.modelFiles;
    }
    if (type === contentTypes.BUNDLE) {
      newPropsObject.fileNameAndroid = fileNameAndroid || this.fileNameAndroid
      newPropsObject.fileNameIos = fileNameIos || this.fileNameIos
    }
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      fileName,
      text,
      thumbnail,
      type,
      name,
      modelFiles,
      fileNameAndroid,
      fileNameIos
    } = this;
    return {
      fileName,
      text,
      thumbnail,
      type,
      name,
      modelFiles,
      fileNameAndroid,
      fileNameIos
    };
  }

  getFilesToUpload() {
    const files = {
      fileName: this.fileName,
      thumbnail: this.thumbnail,
      fileNameAndroid: this.fileNameAndroid,
      fileNameIos: this.fileNameIos,
    }
    if (this.modelFiles) {
      files.obj = this.modelFiles.obj
    }
    return files;
  }

  setFilenames({ fileName, thumbnail, obj, fileNameAndroid, fileNameIos }) {
    this.fileName = fileName;
    this.thumbnail = thumbnail
    if (this.modelFiles) {
      this.modelFiles.obj = obj;
    }
    if (this.type === contentTypes.BUNDLE) {
      this.fileNameAndroid = fileNameAndroid
      this.fileNameIos = fileNameIos
    }
  }

  validate() {
    const errors = {}
    let isValid = true;
    if ((this.type === contentTypes.RTMP || this.type === contentTypes.TEXT) && !this.text) {
      errors.text = 'Text is required';
      isValid = false;
    }
    if ((this.type !== contentTypes.RTMP &&
      this.type !== contentTypes.TEXT &&
      this.type !== contentTypes.MODEL &&
      this.type !== contentTypes.BUNDLE) && !this.fileName) {
      errors.fileName = 'File is requred';
      isValid = false;
    }
    if (this.type === contentTypes.MODEL && !this.modelFiles.obj) {
      errors.fileName = 'File file is requred';
      isValid = false;
    }
    if (this.fileName && this.fileName instanceof File && this.fileName.size > maxFileLength) {
      errors.fileName = 'Maximum file size is 1 GB!';
      isValid = false;
    }
    if (this.thumbnail && this.thumbnail instanceof File && this.thumbnail.size > maxFileLength) {
      errors.thumbnail = 'Maximum file size is 1 GB!';
      isValid = false;
    }
    if (this.fileNameAndroid && this.fileNameAndroid instanceof File && this.fileNameAndroid.size > maxFileLength) {
      errors.fileNameAndroid = 'Maximum file size is 1 GB!';
      isValid = false;
    }
    if (this.fileNameIos && this.fileNameIos instanceof File && this.fileNameIos.size > maxFileLength) {
      errors.fileNameIos = 'Maximum file size is 1 GB!';
      isValid = false;
    }
    if (this.type === contentTypes.MODEL && this.modelFiles) {
      const { obj } = this.modelFiles;
      if (obj instanceof File && obj.size > maxFileLength) {
        errors.fileName = 'Maximum file size is 1 GB!';
        isValid = false;
      }
    }
    if (this.type === contentTypes.BUNDLE) {
      if (!this.fileNameAndroid) {
        errors.fileNameAndroid = 'Bundle for Android is required'
        isValid = false;
      }
      if (!this.fileNameIos) {
        errors.fileNameIos = 'Bundle for IOS is required'
        isValid = false;
      }
    }
    if (!isValid) {
      throw new ValidationError(errors);
    }
  }
}
