const browserStorage = window.sessionStorage;

const defaultUserInfo = {
  login: "",
  isSuper: false,
  token: "",
}

const storage = {
  token: "",
  getToken: function () {
    if (!this.token) {
      this.getUserInfo()
    }
    return this.token;
  },
  getUserInfo: function() {
    const storeInfo = browserStorage.getItem('userInfo');
    if (!storeInfo) {
      return defaultUserInfo;
    }
    const info = JSON.parse(storeInfo);
    this.token = info.token;
    return Object.assign(defaultUserInfo, info);
  },
  setUserInfo: function (info) {
    browserStorage.setItem('userInfo', JSON.stringify(info));
    if (info.token) {
      this.token = info.token;
    }
  },
  dropUserInfo: function() {
    browserStorage.removeItem('userInfo');
    this.token = "";
  },
}

export default storage;
