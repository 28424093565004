import authStorage from '../helpers/authStorage';
import { observable, decorate, action } from "mobx";
import * as api from '../api/auth';

class Session {
  login = "";
  isSuper = false;
  isLoggedIn = false;

  constructor() {
    const { login, isSuper, token } = authStorage.getUserInfo();
    this.login = login;
    this.isSuper = isSuper;
    this.isLoggedIn = !!token;
  }

  loginUser(payload) {
    return api.loginUser(payload)
      .then(({ data: { token, login, role } }) => {
        const isSuper = role === 'SuperAdmin';
        authStorage.setUserInfo({
          token,
          login,
          isSuper,
        });
        this.isLoggedIn = true;
        this.login = login;
        this.isSuper = isSuper;
      });
  }

  discardToken() {
    this.isLoggedIn = false;
    this.login = "";
    this.isSuper = false;
    authStorage.dropUserInfo();
  }
}

decorate(Session, {
  login: observable,
  isSuper: observable,
  isLoggedIn: observable,
  loginUser: action,
  discardToken: action,
});

export default Session;