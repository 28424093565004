import getInstance from './instance';
export { upload } from './file';

export const getList = options => getInstance()
  .get('/api/v1/admin/contents/', {
    params: options
  });
export const getItem = id => getInstance()
  .get(`/api/v1/admin/contents/${id}`);
export const update = (item, id, params) => getInstance()
  .patch(`/api/v1/admin/contents/${id}`, item, {
    params
  });
export const create = (item, params) => getInstance()
  .post('/api/v1/admin/contents/', item, { params });
export const remove = (id, params) => getInstance()
  .delete(`/api/v1/admin/contents/${id}`, { params });

// export const getList = options => new Promise((resolve, reject) => {
//   resolve({
//     data: [
//       {
//         name
//       }
//     ]
//   })
// })
// export const getItem = id => getInstance()
//   .get(`/api/v1/admin/contents/${id}`);
// export const update = (item, id) => getInstance()
//   .patch(`/api/v1/admin/contents/${id}`, item);
// export const create = item => getInstance()
//   .post('/api/v1/admin/contents/', item);
// export const remove = id => getInstance()
//   .delete(`/api/v1/admin/contents/${id}`);