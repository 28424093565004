import { useState, useCallback } from "react";

export default function useDeleteDialog(onDelete) {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState();

  const handleDelete = useCallback(item => {
    setItem(item);
    setOpen(true);
  }, [setItem, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen]);

  const handleExit = useCallback(() => {
    setItem(undefined);
  }, [setItem]);

  const handleConfirm = useCallback(() => {
    onDelete(item);
  }, [item, onDelete]);

  return { open, item, handleClose, handleExit, handleConfirm, handleDelete };
}
