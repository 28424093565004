import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import ValidationError from '../../store/base/ValidationError';
import { designElementsTypes } from '../../constants';
import isIntHandler from '../../helpers/isIntHandler';

export default containerName => Wrapped => {
  class TextElementDetailWrapper extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        errors: {
          text: false,
        },
        loading: false,
        text: '',
      }
    }

    async componentDidMount() {
      const { match: { params: { id } } } = this.props;
      const container = this.props[containerName]
      if (id) {
        this.item = await container.getDetail(id);
        this.contentToState();
      }
    }

    contentToState() {
      const {
        name,
        type,
        text,
      } = this.item;
      this.setState({
        name,
        type,
        text: text || "",
      });
    }

    handleSubmit = async e => {
      e.preventDefault();
      if (this.state.loading) {
        return;
      }
      const {
        text
      } = this.state;


      const content = {
        text,
      };
      
      this.setState({
        loading: true,
      });
      
      try {
        await this.updateItem(content);
      } catch(e) {
        if (e instanceof ValidationError) {
          const errors = {
            ...e.componentErrors,
          }
          this.setState({
            errors: {
              ...this.state.errors,
              ...errors,
            }
          });
        } else {
          throw e;
        }
      } finally {
        this.setState({
          loading: false
        });
      }
    }

    async updateItem(content) {
      await this.props[containerName]
        .update(this.item, content);
      this.contentToState();
    }

    handleChange = e => {
      const { target } = e;
      const { name, value } = target;
      const { type } = this.state;
      if (type === designElementsTypes.NUMBER
          && name === 'text'
          && !isIntHandler(value)) {
        return;
      }
      const newState = {
        [name]: value
      };
      if (this.state.errors[name]) {
        newState.errors = {
          ...this.state.errors,
          [name]: false,
        }
      }
      this.setState(newState);
    }
    
    render() {
      return (
        <Wrapped
          {...this.props}
          {...this.state}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      );
    }
  }

  return inject(containerName)(
    withRouter(TextElementDetailWrapper)
  );
}
