import React, { useState, useCallback, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import CustomToolbar from '../../Components/CustomToolbar';
import SelectAutocomplete from '../../Components/SelectAutocomplete';
import CustomLinearProgress from '../../Components/CustomLinearProgress';
import useLoadOptions from '../../hooks/useLoadOptions';
import Fade from '@material-ui/core/Fade';
import useSelectedCampaignId from '../../hooks/useSelectedCampaignId';
import { useContent } from '../../hooks/useStores';
import BasePapper from '../../Components/BasePapper';
import SubmitButton from '../../Components/SubmitButton';
import { update, getItem } from '../../api/groundElement';
import useSaveNotifier from '../../hooks/useSaveNotifier';


const GroundElemet = ({ enqueueSnackbar }) => {
  const campaignId = useSelectedCampaignId();
  const content = useContent();
  const [loading, setLoading] = useState(false)
  const [selectedContent, setContent] = useState('');
  const { handleInputChanged, options } = useLoadOptions(content, {}, { spaceId: campaignId });
  const handleContentChanged = useCallback((newContent) => {
    setContent(newContent);
  }, [setContent]);
  const notifier = useSaveNotifier(enqueueSnackbar);
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await update({ contentId: selectedContent.key });
      notifier.success();
    } catch {
      notifier.error();
    } finally {
      setLoading(false);
    }
  }, [selectedContent]);

  useEffect(() => {
    getItem({ spaceId: campaignId })
      .then((item) => {
        if (item) {
          const { name, _id } = item;
          setContent({ label: name, key: _id });
        }
      })
  }, []);

  return (
    <BasePapper>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit>
        <CustomLinearProgress />
      </Fade>
      <CustomToolbar disableGutters>
        Ground Element
      </CustomToolbar>
      <form onSubmit={handleSubmit} >
        <SelectAutocomplete
          options={options}
          onInputChange={handleInputChanged}
          placeholder="Select content"
          cacheOptions
          value={selectedContent}
          onChange={handleContentChanged}
          textFieldProps={{
            label: 'Content',
            InputLabelProps: {
              shrink: true,
            },
          }}
          name="contentId"
        />

        <SubmitButton
          disabled={loading}
        >
          Save
        </SubmitButton>
      </form>
    </BasePapper>
  );
};

export default withSnackbar(GroundElemet)