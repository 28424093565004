import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinkItemWithActions from '../../Components/LinkItemWithActions';

const CampaignListItem = ({
  onDelete,
  item,
  ...rest
}) => {
  const handleDelete = useCallback(() => {
    onDelete(item)
  }, [item, onDelete]);
  return (
    <LinkItemWithActions {...rest}>
      <IconButton component={Link} to={`/campaign-detail/${item.id}`}>
        <EditIcon/>
      </IconButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon/>
      </IconButton>
    </LinkItemWithActions>
  );
};

export default CampaignListItem;