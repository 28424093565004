import { useCallback } from "react";

export default function useSaveNotifier(enqueueSnackbar) {
  const success = useCallback((message='Successfully saved') => {
    enqueueSnackbar(message, {
      variant: 'success',
    });
  }, [enqueueSnackbar]);
  const error = useCallback((message='Unexpected server error') => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  }, [enqueueSnackbar]);
  return { success, error };
}