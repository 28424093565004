import BaseModel from "../base/BaseModel";

export default class ClientModel extends BaseModel {

  mapProps({
    _id,
    password,
    role,
    permissions,
    login,
    isSuper,
  }) {
    const newPropsObject = {
      id: _id || this.id,
      login,
      password,
      isSuper: isSuper !== false ?  role === 'SuperAdmin' || isSuper : false,
      permissions,
    }
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      login,
      password,
      isSuper,
      permissions,
    } = this;
    return {
      login,
      password,
      isSuper,
      permissions,
    };
  }
}
