import React from 'react';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import CustomToolbar from '../../Components/CustomToolbar';
import MarkerDetailContainer from './MarkerDetailContainer';
import SelectAutocomplete from '../../Components/SelectAutocomplete';
import FileInput from '../../Components/FileInput';
import CustomLinearProgress from '../../Components/CustomLinearProgress';

const styles = theme => ({
  form: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  image: {
    maxWidth: '100%',
    marginBottom: theme.spacing.unit * 2,
  },
  idsList: {
    marginTop: theme.spacing.unit * 2
  },

  nameInput: {
    display: 'block',
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  
  selectInput: {
    '& + &': {
      marginTop: theme.spacing.unit * 2,
    }
  }
})

const MarkerDetail = observer(({
  onChange,
  onSubmit,
  classes,
  errors,
  loading,
  fileAccept,
  name,
  thumbnailFileName,
  thumbnailUrl,
  onLoadOptions,
  onContentChange,
  content,
  width
}) => {
  return (
    <Paper>
      { loading && <CustomLinearProgress/> }
      <CustomToolbar>
        Marker
      </CustomToolbar>
      <form onSubmit={onSubmit} className={classes.form}>
      <Grid container spacing={16}>
        <Grid item xs={7}>
          <div>
            <TextField
              name="name"
              value={name}
              onChange={onChange}
              label="Name"
              className={classes.nameInput}
              required
            />
            <TextField
              name="width"
              value={width}
              onChange={onChange}
              label="Width"
              className={classes.nameInput}
              required
              error={errors.width}
              helperText={errors.width}
            />
            <SelectAutocomplete
              loadOptions={onLoadOptions}
              placeholder="Select content"
              cacheOptions
              defaultOptions
              value={content}
              onChange={onContentChange}
              isAsync
              textFieldProps={{
                label: 'Content',
                InputLabelProps: {
                  shrink: true,
                },
                helperText: errors.content,
                error: errors.content,
              }}
              name="contentId"
              className={classes.selectInput}
            />
          </div>
        </Grid>
        <Grid item xs={5}>
          {thumbnailUrl && (
            <img
              className={classes.image}
              src={thumbnailUrl}
              alt={name + " image"}
            />
          )}
          <FileInput
            fileName={thumbnailFileName}
            onChange={onChange}
            name="thumbnail"
            errorText={errors.thumbnail}
            error={errors.thumbnail}
            inputProps={{
              accept: fileAccept
            }}
            buttonText="Upload preview"
            helperText={
              <>
                Maximum file size 2 MB <br/>
                Image cannot be transparent
              </>
            }
          />
        </Grid>
        <Grid item xs={7}>
          <Button
            color="secondary"
            disabled={loading}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
      </form>
    </Paper>
  );
});

MarkerDetail.propTypes = {
  
};

export default MarkerDetailContainer(
  withStyles(styles)(MarkerDetail)
);