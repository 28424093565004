import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import FileInputButton from './FileInputButton';

const styles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
})

const FileListTitle = ({
  classes,
  text,
  onChange,
  fileAccept=""
}) => {
  return (
    <div className={classes.header}>
      <Typography variant="subtitle1">{text}</Typography>
      <FileInputButton
        inputProps={{
          accept: fileAccept,
          multiple: true,
        }}
        component={
          ({onClick}) => (
            <IconButton onClick={onClick}>
              <AddIcon/>
            </IconButton>
          )
        }
        onChange={onChange}
      />
    </div>
  );
};

export default withStyles(styles)(FileListTitle);