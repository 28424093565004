import React from 'react';
import List from '@material-ui/core/List';
import FileListItem from './FileListItem';


const FilesList = ({
  files,
  onDelete,
}) => {
  return (
    <List>
      { files.map((file, index) => (
        <FileListItem
          key={file.fileName}
          file={file}
          onDelete={onDelete}
          index={index}
        />
      ))}
    </List>
  );
};

export default FilesList;