import PersonIcon from '@material-ui/icons/Person';
import AppsIcon from '@material-ui/icons/Apps';
import Clients from '../layouts/Clients';
import ClientDetail from '../layouts/ClientDetail';
import Campaigns from '../layouts/Campaigns/Campaigns';
import CampaignDetail from '../layouts/CampaignDetail/CampaignDetail';
import SuperCampaign from '../layouts/SuperCampaign/SuperCampaign';
import ChangePassword from '../layouts/ChangePassword/ChangePassword';
import LockIcon from '@material-ui/icons/Lock';
import BarIcon from '@material-ui/icons/BarChart';
import Analytics from '../layouts/Analytics/Analytics';

export default [
  {
    invisible: true,
    component: SuperCampaign,
    path:"/campaign/:campaignId/",
  },
  {
    sidebarIcon: AppsIcon,
    sidebarText: "Campaigns",
    component: Campaigns,
    path:"/campaigns",
    exact: true,
  },
  {
    component: CampaignDetail,
    path:"/campaign-detail/:id",
    exact: true,
    invisible: true,
  },
  {
    component: CampaignDetail,
    path:"/campaign-detail/",
    exact: true,
    invisible: true,
  },
  {
    invisible: true,
    component: ClientDetail,
    path:"/client",
    exact: true
  },
  {
    sidebarIcon: PersonIcon,
    sidebarText: "Clients",
    component: Clients,
    path:"/clients",
    exact: true,
  },
  {
    sidebarIcon: BarIcon,
    sidebarText: "Analytics",
    component: Analytics,
    path: "/analytics",
  },
  { divider: true },
  {
    sidebarIcon: LockIcon,
    sidebarText: "Change password",
    component: ChangePassword,
    path: "/change-password",
  },
  {
    invisible: true,
    redirect: true,
    to:"/campaigns"
  },
]