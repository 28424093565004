import { changePassword } from '../../api/changePassword';
import { useCallback, useState } from 'react';
import { WrongPasswordError } from './WrongPasswordError';

export function useChangePassword({ oldPassword, newPassword }) {
  const [loading, setLoading] = useState(false);
  const onChangePassword = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true)
    try {
      await changePassword({ oldPassword, newPassword });
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        throw new WrongPasswordError('Incorrect password');
      } else {
        throw e;
      }
    } finally {
      setLoading(false);
    }
  }, [oldPassword, newPassword, loading]);

  return [onChangePassword, loading];
}
