import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CustomToolbar from '../../Components/CustomToolbar';
import { contentTypes, maxFileLength, } from '../../constants';
import {  useImageFile } from './hooks';
import { maxLengthValidation } from '../../validators/validators';
import FadedProgress from '../../Components/FadedProgress';
import { contentTypesAlias } from './ContentWrapper';
import Thumbnail from './Thumbnail';
import useTextState from '../../hooks/useTextState';


const styles = theme => ({
  nameInput: {
    marginRight: theme.spacing.unit * 2,
  },
  typeSelect: {
    minWidth: 120,
    textTransform: 'capitalize',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  submit: {
    marginTop: theme.spacing.unit * 2,
  }
});

const maxLengthValidator = maxLengthValidation(maxFileLength, 'Maximum file size is 1 GB!');

function TextContentDetail({
  loading,
  onSubmit,
  item,
  type,
  onTypeChanged,
  classes
}) {
  const { state: { name, text }, onChange } = useTextState({
    name: (item && item.name) || '',
    text: (item && item.text) || '',
  });

  const thumbnail = useImageFile(
    item && item.thumbnail,
    maxLengthValidator
  );

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    let isValid = ![thumbnail.validate()]
      .some(error => !!error);
    if (isValid) {
      onSubmit({
        name,
        thumbnail: thumbnail.file,
        type,
        text
      });
    }
  }, [onSubmit, name, thumbnail, text])
  return (
    <>
      <FadedProgress
        loading={loading}
      />
      <CustomToolbar disableGutters>
        Content
      </CustomToolbar>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={16}>
          <Grid item direction="row" xs={7}>
            <div>
              <TextField
                name="name"
                value={name}
                onChange={onChange}
                label="Name"
                className={classes.nameInput}
                required
              />
              <TextField
                select
                value={type}
                className={classes.typeSelect}
                name="type"
                onChange={onTypeChanged}
                label="Type"
              >
                {Object.values(contentTypes).map(type => (
                  <MenuItem
                    key={type}
                    value={type}
                    className={classes.menuItem}
                  >
                    {contentTypesAlias[type]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                value={text}
                name="text"
                onChange={onChange}
                fullWidth
                multiline
                rows={5}
                label="Text"
                required
              />
              <Button
                color="secondary"
                disabled={loading}
                type="submit"
                variant="contained"
                className={classes.submit}
              >
                Save
              </Button>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Thumbnail
              fileName={thumbnail.fileName}
              preview={thumbnail.preview}
              onChange={thumbnail.onChange}
              error={thumbnail.error}
            />
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default withStyles(styles)(
  TextContentDetail
)