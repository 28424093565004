import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  fileInput: {
    display: "none"
  },
  fileInputLabel: {
    display: "inline-flex",
    flexDirection: "column"
  },
  fileName: {
    display: "block"
  },
  button: {
    marginBottom: theme.spacing.unit,
  },
  errorText: {
    color: 'red',
  },
});

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  
  handleClick = e => {
    e.preventDefault();
    this.inputRef.current.click();
  }

  render() {
    const {
      classes,
      onChange,
      value,
      inputProps,
      id,
      buttonText,
      buttonProps,
      name,
      className,
      fileName,
      errorText,
      error,
      helperText,
    } = this.props;

    return (
      <div className={className}>
        <input
          ref={this.inputRef}
          className={classes.fileInput}
          id={id}
          type="file"
          onChange={onChange}
          value={value}
          name={name}
          { ...inputProps }
        />
        <label
          htmlFor={id}
          className={classes.fileInputLabel}
        >
          <div>
            <Button
              color="primary"
              variant="contained"
              component="span"
              onClick={this.handleClick}
              className={classes.button}
              { ...buttonProps }
            >
              {buttonText || "Upload File"}
            </Button>
            {fileName && (
              <Typography
                className={classes.fileName}
                variant="caption"
                component="span"
              >
                Filename: {fileName}
              </Typography>
            )}
            {helperText && (
              <Typography
                variant="caption"
                className={classes.helperText}
              >
                {helperText}
              </Typography>
            )}
            {error && (
              <Typography
                variant="caption"
                className={classes.errorText}
              >
                {errorText}
              </Typography>
            )}
          </div>
        </label>
      </div>
    );
  }
}

export default withStyles(styles)(FileInput);