export default function (fieldName, isMultiple=false, isImage=false) {
  return function({ target: { files } }) {
    if (files.length === 0) {
      this[fieldName] = '';
      if (!isMultiple) {
        const state = {
          [`${fieldName}FileName`]: ''
        };
        if (isImage) {
          state[`${fieldName}Url`] = '';
        }
        this.setState(state);
      }
      return;
    }
    if (!isMultiple) {
      [files] = files;
    }
    this[fieldName] = files;
    if (!isMultiple) {
      const state = {
        [`${fieldName}FileName`]: files.name,
      };
      if (isImage) {
        state[`${fieldName}Url`] = URL.createObjectURL(files);
      }
      this.setState(state);
    }
  }
}