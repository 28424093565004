import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchToolbar from '../../Components/SearchToolbar';
import ContentContainer from './ContentContainer';
import ContentListItem from './ContentListItem';
import NoItems from '../../Components/NoItems';
import AddButton from '../../Components/AddButton';
import CustomPagination from '../../Components/CustomPagination';
import CustomLinearProgress from '../../Components/CustomLinearProgress';

const styles = theme => ({
  paginator: {
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  grid: {
    padding: theme.spacing.unit * 2,
  },
})

const ContentList = observer(({
  content,
  onDelete,
  classes,
  offset,
  onPageChange,
  limit,
  loading,
  onSearchChange,
  search,
  match
}) => {
  const { campaignId } = match.params;
  let addButtonLink = '/content';
  if(campaignId) {
    addButtonLink = `/campaign/${campaignId}` + addButtonLink
  }
  return (
    <Paper>
      { loading && (
        <CustomLinearProgress className={classes.progressBar}/>
      )}
      <SearchToolbar
        onChange={onSearchChange}
        search={search}
      >
        Content
      </SearchToolbar>
      { content.list.length !== 0 ? (
        <Grid
          container
          spacing={16}
          className={classes.grid}
        >
          {content.list.map(item => (
            <Grid key={item.id} item xs={6}>
              <ContentListItem
                content={item}
                onDelete={onDelete}
                campaignId={campaignId}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoItems>
          { loading ? "Loading..." : "No items"}
        </NoItems>
      )}
      <CustomPagination
        limit={limit}
        offset={offset}
        total={content.count}
        className={classes.paginator}
        onClick={onPageChange}
      />
      <AddButton
        color="secondary"
        component={Link}
        to={addButtonLink}
      />
    </Paper>
  );
});

export default ContentContainer(withStyles(styles)(ContentList));