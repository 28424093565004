import ModelWithFiles from "../base/ModelWithFiles";

export default class PicElementModel extends ModelWithFiles {

  mapProps({ _id, name, type, fileName }) {
    Object.assign(this, {
      id: _id || this.id,
      name: name || this.name,
      type: type || this.type,
      fileName: fileName || this.fileName,
    });
  }

  getObject() {
    const { fileName } = this; 
    return { fileName };
  }

  getFilesToUpload() {
    return { fileName: this.fileName }
  }
}
