import { useState, useCallback } from 'react';

export default function useTextState(defaultState, validators) {

  const [state, setState] = useState(defaultState);
  const [errors, setErrors] = useState({});

  const validateField = useCallback((name, value) => {
    if (!validators) {
      return;
    }
    const validator = validators[name];
    if (validator) {
      return validator(value, state);
    }
    return false;
  }, [validators, state]);

  const validate = useCallback(() => {
    const entries = Object.entries(state);
    for (let i = 0; i < entries.length; i++) {
      const [name, value] = entries[i];
      const error = validateField(name, value);
      if (error) {
        setErrors({
          ...errors,
          [name]: error
        });
        return error;
      }
    }
  }, state, setErrors, validateField, errors)

  const onChange = useCallback(({ target: { value, name, type, checked } }) => {
    if (type === 'checkbox') {
      value = checked;
    }
    const error = validateField(name, value);
    if (error) {
      setErrors({
        ...errors,
        [name]: error
      });
    } else {
      delete errors[name];
      setErrors(errors);
    }
    setState({
      ...state,
      [name]: value
    });
  }, [validateField, setState, setErrors, state, errors]);

  return { errors, state, onChange, validate, setState, setErrors };
}