import PaginatedModelContainer from "../base/PaginatedModelContainer";

export default class CampaignsModelContainer extends PaginatedModelContainer {
  async create(data, options) {
    const item = new this.Model(data);
    try {
      const response = await this.netService.create(item, options);
      this.notifier.showSuccessMessage('Successfully saved');
      item.mapProps(response);
      this.list.push(response);
      return item;
    }
    catch(e) {
      if (e.response && e.response.status === 409) {
        this.notifier.showErrorMessage('Name must be unique');
      } else {
        throw e;
      }
    }
  }
  
  async update(item, data, options) {
    item.mapProps(data);
    try {
      const response = await this.netService.update(item, options);
      this.notifier.showSuccessMessage('Successfully saved');
      item.mapProps(response);
    } catch(e) {
      if (e.response && e.response.status === 409) {
        this.notifier.showErrorMessage('Name must be unique');
      } else {
        throw e;
      }
    }
  }

  async getDetail(id) {
    const data = await this.netService.getItem(id);
    return new this.Model(data);
  }
}