import React, { useCallback } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


const ListItemDelete = ({
  icon: Icon,
  text,
  classes = {},
  item,
  onDelete,
}) => {

  const handleDelete = useCallback(() => {
    onDelete(item)
  }, [item, onDelete]);
  
  return (
    <ListItem >
      {Icon && (
        <ListItemIcon>
          <Icon
            classes={{
              colorPrimary: classes.text
            }}
            color="primary"
          />
        </ListItemIcon>
      )}
      <ListItemText
        classes={{
          primary: classes.text
        }}
        primary={text}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}


export default ListItemDelete;