import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from "lodash";
import { inject } from 'mobx-react';
import DeleteDialog from '../../Components/DeleteDialog';

export default function ContentContainer(Wrapped) {
  class ContentWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = ({
        offset: 0,
        search: "",
        loading: false,
        openDeleteDialog: false,
        itemToDelete: false,
        deleteLoading: false,
      });
      this.searchItem = debounce(this.searchItem, 600);
    }
    
    componentDidMount() {
      this.loadData();
    }

    handleDeleteRequrest = item => {
      this.setState({
        itemToDelete: item,
        openDeleteDialog: true,
      });
    }

    handleCloseDeleteDialog = () => {
      this.setState({
        itemToDelete: false,
        openDeleteDialog: false,
      })
    }

    handleDelete = async () => {
      const { itemToDelete: item } = this.state;
      const { campaignId: spaceId } = this.props.match.params;
      this.setState({ deleteLoading: true });
      try {
        await this.props.content.delete(item, { spaceId });
        this.handleCloseDeleteDialog();
      } finally {
        this.setState({ deleteLoading: false });
      }
    }

    handlePageChange = async (e, offset) => {
      const { search } = this.state;
      await this.loadData({ offset, search })
      this.setState({offset});
    }

    handleSearchChange = async ({ target: { value }}) => {
      this.setState({ search: value });
      this.searchItem();
    }

    async loadData(options={}) {
      const { campaignId: spaceId } = this.props.match.params;
      if (spaceId) {
        options.spaceId = spaceId;
      }
      this.setState({ loading: true });
      try {
        await this.props.content.loadList(options);
      }
      finally {
        this.setState({ loading: false })
      }
    }
    
    searchItem() {
      const { search, offset } = this.state;
      this.loadData({ search, offset });
    }

    render() {
      const {
        openDeleteDialog,
        itemToDelete,
        deleteLoading,
        ...state
      } = this.state;
      return (
        <React.Fragment>
          <Wrapped
            {...this.props}
            {...state}
            onDelete={this.handleDeleteRequrest}
            onPageChange={this.handlePageChange}
            onSearchChange={this.handleSearchChange}
            limit={10}
          />
          <DeleteDialog
            open={openDeleteDialog}
            onDelete={this.handleDelete}
            onClose={this.handleCloseDeleteDialog}
            description={`${itemToDelete.name}`}
            loading={deleteLoading}
          />
        </React.Fragment>
      );
    }
  }

  return inject('content')(
    withRouter(ContentWrapper)
  );
}