import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  }
})

const NoItems = ({ children, classes }) => {
  return (
    <Typography
      variant="body1"
      align="center"
      className={classes.root}
    >
      { children || "No items" }
    </Typography>
  );
};

export default withStyles(styles)(NoItems);