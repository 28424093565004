import { decorate, action, observable } from "mobx";
/* eslint-disable no-unused-vars */
import BaseModel from "./BaseModel";
import BaseNetService from "./BaseNetService";
import Notifier from "../Notifier";
/* eslint-enable no-unused-vars */

export default class BaseModelContainer {
  /**
   * 
   * @param {BaseModel} Model 
   * @param {BaseNetService} netService 
   * @param {Notifier} notifier
   */
  constructor(Model, netService, notifier) {
    this.Model = Model;
    this.netService = netService;
    this.list =  [];
    this.notifier = notifier;
  }

  async loadList(options) {
    const data = await this.netService.getList(options);
    const list = data.map(item => new this.Model(item));
    this.list = list;
  }

  async create(data, options) {
    const item = new this.Model(data);
    item.validate();
    const response = await this.netService.create(item, options);
    this.notifier.showSuccessMessage('Successfully saved');
    item.mapProps(response);
    this.list.push(response);
    return item;
  }

  async update(item, data, options) {
    item.mapProps(data);
    item.validate();
    const response = await this.netService.update(item, options);
    this.notifier.showSuccessMessage('Successfully saved');
    item.mapProps(response);
  }

  async delete(item, options) {
    await this.netService.delete(item.id, options);
    this.notifier.showSuccessMessage('Successfully deleted');
    this.list = this.list.filter(i => i.id !== item.id)
  }

  async getDetail(id) {
    let item = this.list.find(i => i.id === id);
    if (!item) {
      const data = await this.netService.getItem(id);
      item = new this.Model(data);
    }
    return item;
  }

  dispose() {
    this.list = [];
  }
}

decorate(BaseModelContainer, {
  loadList: action,
  update: action,
  delete: action,
  dispose: action,
  list: observable,
})
