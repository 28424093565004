import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const AllData = ({ data }) => {
  return (
    <Table>
      <TableBody>
        {data.map(({ name, value }) => (
          <TableRow key={name}>
            <TableCell variant="head" >
              {name}
            </TableCell>
            <TableCell >
              {value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AllData;