import React from 'react';

import imageIcon from '../../icons/image.png';
import modelIcon from '../../icons/model.png';
import RTMPIcon from '../../icons/RTMP.png';
import textIcon from '../../icons/text.png';
import unityIcon from '../../icons/unity.png';
import videoIcon from '../../icons/video.png';
import youtubeIcon from '../../icons/youtube.png';
import { contentTypes } from '../../constants';

const iconsByType = {
  [contentTypes.IMAGE]: imageIcon,
  [contentTypes.MODEL]: modelIcon,
  [contentTypes.RTMP]: RTMPIcon,
  [contentTypes.TEXT]: textIcon,
  [contentTypes.TVIDEO]: youtubeIcon,
  [contentTypes.VIDEO]: videoIcon,
  [contentTypes.BUNDLE]: unityIcon,
}

const ContentIcon = ({ type }) => {
  return (
    <img
      style={{
        display: 'block',
        maxWidth: 40,
      }}
      src={iconsByType[type]}
      alt={type}
    />
  );
};

export default ContentIcon;