import { observable, decorate, action } from "mobx";

class Notifier {
  notifications = [];
  
  enqueueSnackbar = (message, options={}) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message,
      options
    })
  }

  removeSnackbar = currentKey => {
    this.notifications.splice(
      this.notifications.findIndex(({ key }) => currentKey === key),
      1
    )
  }

  showSuccessMessage = message => {
    this.enqueueSnackbar(message, {
      variant: "success"
    });
  }

  showErrorMessage = message => {
    this.enqueueSnackbar(message, {
      variant: "error"
    })
  }
}

decorate(Notifier, {
  notifications: observable,
  enqueueSnackbar: action,
  removeSnackbar: action
});

export default Notifier;