export const contentTypes = {
  MODEL: 'model',
  VIDEO: 'video',
  TVIDEO: 'tvideo',
  IMAGE: 'image',
  RTMP: 'rtmp',
  TEXT: 'text',
  BUNDLE: 'bundle'
}

export const designElementsTypes = {
  NUMBER: 'number',
  PICTURE: 'pic',
  TEXT: 'text',
  MESSAGE: 'message',
}

export const texturesAccept = ".png,.jpg,.bmp";
export const materialsAccept = ".mat, .mtl";
export const objectAccept = ".obj";
export const bundleAccept = "*";
export const maxFileLength = 1048576000;
export const maxMarkerLength = 2097152;