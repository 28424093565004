import getInstance from './instance';

export const getList = options => getInstance()
  .get('api/v1/admin/design-container', {
    params: {
      ...options,
    }
  });
  
export const getItem = id => getInstance()
  .get(`/api/v1/admin/design-container/${id}`);

export const update = (item, id) => getInstance()
  .patch(`/api/v1/admin/design-container/${id}`, item);