import BaseNetService from "./BaseNetService";
import ModelWithFiles from "./ModelWithFiles";

export default class WithFilesNetService extends BaseNetService {
  /**
   * @param {ModelWithFiles} item 
   */
  async create(item, options) {
    this.typeCheck(item);
    const filenames = await this.uploadFiles(item);
    item.setFilenames(filenames);
    const { data } = await this.api.create(item.getObject(), options);
    return data;
  }

  /**
   * @param {ModelWithFiles} item 
   */
  async update(item, options) {
    this.typeCheck(item);
    const filenames = await this.uploadFiles(item);
    item.setFilenames(filenames);
    const { data } = await this.api.update(item.getObject(), item.id, options);
    return data
  }

  async uploadFiles(item) {
    const files = {};
    const query = this.getFilesList(item).map(([name, file]) => {
      if (Array.isArray(file)) {
        return this.uploadArray(file)
          .then(filenames => files[name] = filenames)
      }
      return this.api.upload(file)
      .then(filename => files[name] = filename)
    });
    await Promise.all(query);
    return files;
  }

  async uploadArray(files) {
    const filenames = [];
    const query = files.map((file, index) => (
      this.api.upload(file)
        .then(filename => filenames[index] = filename)
    ));
    await Promise.all(query);
    return filenames
  }

  getFilesList(item) {
    const files = []
    Object.entries(item.getFilesToUpload())
      .forEach(([key, value]) => {
        if (value && typeof value !== 'string') {
          files.push([key, value]);
        }
      })
    return files;
  }

  typeCheck(item) {
    if (!(item instanceof ModelWithFiles)) {
      throw new TypeError("Item must be ModelWithFiles");
    }
  }
}