import Notifier from './Notifier';
import Session from './Session';
import ContentModel from './Content/ContentModel';
import ContentModelsContainer from './Content/ContentModelsContainer';
import MarkerModelsContainer from './Marker/MarkerModelsContainer';

import TextElementModel from './DesignElements/TextElementModel';
import * as textDesignElementsApi from '../api/textDesignElements';
import * as messageDesignElemetsApi from '../api/messageDesignElements';
import * as pictureDesignElementsApi from '../api/pictureDesignElements';
import * as clientsApi from '../api/clients';
import * as campaignsApi from '../api/campaigns';

import WithFilesNetService from './base/WithFilesNetService';
import MarkerModel from './Marker/MarkerModel';
import * as contentApi from '../api/content';
import * as markersApi from '../api/marker';
import BaseNetService from './base/BaseNetService';
import DesignElementsContainer from './DesignElements/DesignElementsContainer';
import PicElementModel from './DesignElements/PicElementModel';
import ClientsModelContainer from './Clients/ClientsModelContainer';
import ClientModel from './Clients/ClientModel';
import CampaignsModelContainer from './Campaigns/CampaignsModelContainer';
import CampaignModel from './Campaigns/CampaignModel';
import SelectedCampaign from  './SelectedCampaign';
import { designElementsTypes } from '../constants';

const notifier = new Notifier();
const session = new Session();
const content = new ContentModelsContainer(
  ContentModel,
  new WithFilesNetService(contentApi),
  notifier
);

const markers = new MarkerModelsContainer(
  MarkerModel,
  new WithFilesNetService(markersApi),
  notifier
)

const clients = new ClientsModelContainer(
  ClientModel,
  new BaseNetService(clientsApi),
  notifier
)

const campaigns = new CampaignsModelContainer(
  CampaignModel,
  new BaseNetService(campaignsApi),
  notifier
)

const textDesignElements = new DesignElementsContainer(
  TextElementModel,
  new BaseNetService(textDesignElementsApi),
  notifier,
  designElementsTypes.TEXT
)

const messageDesignElements = new DesignElementsContainer(
  TextElementModel,
  new BaseNetService(messageDesignElemetsApi),
  notifier,
  designElementsTypes.MESSAGE
)

const pictureDesignElements = new DesignElementsContainer(
  PicElementModel,
  new WithFilesNetService(pictureDesignElementsApi),
  notifier,
  designElementsTypes.PICTURE
)

const selectedCampaign = new SelectedCampaign();

export default {
  notifier,
  session,
  content,
  markers,
  textDesignElements,
  messageDesignElements,
  pictureDesignElements,
  clients,
  campaigns,
  selectedCampaign,
}