import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const SwitchRenderer = ({ routes }) => {
  return (
    <Switch>
      {routes.map(({ redirect, divider, ...props }, key) => {
        if (divider) {
          return null;
        }
        const Item = redirect ? Redirect : Route;
        return <Item {...props} key={key} />
      })}
    </Switch>
  );
};

export default SwitchRenderer;