import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import PropTypes from 'prop-types';
import addCampaignIdToRoute from '../../helpers/addCampaignIdToRoute';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&::after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    }
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%',
  }
})

class MarkerListItem extends Component {
  handleDelete = e => {
    const { marker, onDelete } = this.props;
    onDelete(marker);
  }

  render() {
    const { marker, classes, campaignId } = this.props;
    const to = addCampaignIdToRoute(`/marker/${marker.id}`, campaignId);
    return (
      <Card
        role={undefined}
        classes={{ root: classes.root }}
      >
        <CardHeader
          title={
            <Link
              to={to}
              className={classes.link}
            >
              {marker.name}
            </Link>
          }
          action={(
            <IconButton onClick={this.handleDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        />
        <CardMedia
          className={classes.cardMedia}
          image={marker.fileName}
        />
      </Card>
    );
  }
}

MarkerListItem.propTypes = {
  marker: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default observer(withStyles(styles)(MarkerListItem));