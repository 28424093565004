import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import SearchToolbar from '../../Components/SearchToolbar';
import NoItems from '../../Components/NoItems';
import AddButton from '../../Components/AddButton';
import CustomPagination from '../../Components/CustomPagination';
import FadedProgress from '../../Components/FadedProgress';
import usePaginatedContainer from '../../hooks/usePaginatedContainer';
import { useClients } from '../../hooks/useStores';
import ListItemDeletable from '../../Components/ListItemDeletable';
import useDeleteWithContainer from '../../hooks/useDeleteWithContainer';
import DeleteDialog from '../../Components/DeleteDialog';

const styles = theme => ({
  paginator: {
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  grid: {
    padding: theme.spacing.unit * 2,
  },
})

const Clients = observer(({
  classes,
}) => {
  const clients = useClients();
  const clientsPag = usePaginatedContainer(clients);
  const deleteDialog = useDeleteWithContainer(clients);
  return (
    <>
      <Paper>
        <FadedProgress loading={clientsPag.loading} />
        <SearchToolbar
          onChange={clientsPag.onSearchChange}
          search={clientsPag.search}
        >
          Clients
      </SearchToolbar>
        {clients.list.length !== 0 ? (
          <List>
            {clients.list.map(item => (
              <ListItemDeletable
                key={item.id}
                text={item.login}
                item={item}
                onDelete={deleteDialog.handleDelete}
              />
            ))}
          </List>
        ) : (
            <NoItems>
              {clientsPag.loading ? "Loading..." : "No items"}
            </NoItems>
          )}
        <CustomPagination
          limit={30}
          offset={clientsPag.offset}
          total={clients.count}
          className={classes.paginator}
          onClick={clientsPag.onPageChange}
        />
        <AddButton
          color="secondary"
          component={Link}
          to="/client"
        />
      </Paper>
      <DeleteDialog
        open={deleteDialog.open}
        onDelete={deleteDialog.handleConfirm}
        onClose={deleteDialog.handleClose}
        onExit={deleteDialog.handleExit}
        description={`${deleteDialog.item ? deleteDialog.item.login : ''}`}
        loading={deleteDialog.loading}
      />
    </>
  );
});

export default withStyles(styles)(Clients);