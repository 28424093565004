import React, { useMemo } from 'react';
import { useSelectedCampaign } from '../../hooks/useStores';
import { getLinks } from '../../routes/clientRouter';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { observer } from 'mobx-react-lite';
import ListItemLink from '../../Components/ListItemLink';

const DrawerCampaignsLinks = observer(() => {
  const { campaignId } = useSelectedCampaign();
  const routes = useMemo(() => {
    if (campaignId) {
      return getLinks(campaignId);
    }
  }, [campaignId]);
  if (!routes) {
    return null;
  }
  return (
    <List>
      {routes
        .filter(({ invisible }) => !invisible)
        .map((route, index) => (
          !route.divider ? (
            <ListItemLink
              text={route.sidebarText}
              icon={route.sidebarIcon}
              to={route.path}
              key={index}
            />
          ) : (
            <Divider key={index} />
          )
        ))}
    </List>
  );
});

export default DrawerCampaignsLinks;