import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CustomToolbar from '../../Components/CustomToolbar';
import { contentTypes, maxFileLength, } from '../../constants';
import {  useFile, useImageFile } from './hooks';
import Thumbnail from './Thumbnail';
import FileInput from '../../Components/FileInput';
import { maxLengthValidation, combineValidators, fileIsRequiredValidation } from '../../validators/validators';
import FadedProgress from '../../Components/FadedProgress';
import { contentTypesAlias } from './ContentWrapper';
import useTextState from '../../hooks/useTextState';

const styles = theme => ({
  nameInput: {
    marginRight: theme.spacing.unit * 2,
  },
  typeSelect: {
    minWidth: 120,
    textTransform: 'capitalize',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  fileInput: {
    marginTop: theme.spacing.unit * 2,
  },
});

const maxLengthValidator = maxLengthValidation(maxFileLength, 'Maximum file size is 1 GB!');
const fileValidator = combineValidators(
  fileIsRequiredValidation(),
  maxLengthValidator,
);

function DefaultContentDetail({
  loading,
  onSubmit,
  item,
  type,
  onTypeChanged,
  classes,
  fileAccept
}) {
  const { state, onChange } = useTextState({
    name: (item && item.name) || ''
  });
  const { name } = state;

  const contentFile = useFile(
    item && item.fileName, 
    fileValidator
  );
  const thumbnail = useImageFile(
    item && item.thumbnail,
    maxLengthValidator
  );

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    let isValid = ![contentFile.validate(), thumbnail.validate()]
      .some(error => !!error);
    if (isValid) {
    onSubmit({
      name,
      fileName: contentFile.file,
      thumbnail: thumbnail.file,
      type
    });
    }
  }, [onSubmit, name, contentFile, thumbnail])
  return (
    <>
      <FadedProgress
        loading={loading}
      />
      <CustomToolbar disableGutters>
        Content
      </CustomToolbar>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={16}>
          <Grid item xs={7}>
            <div>
              <TextField
                name="name"
                value={name}
                onChange={onChange}
                label="Name"
                className={classes.nameInput}
                required
              />
              <TextField
                select
                value={type}
                className={classes.typeSelect}
                name="type"
                onChange={onTypeChanged}
                label="Type"
              >
                {Object.values(contentTypes).map(type => (
                  <MenuItem
                    key={type}
                    value={type}
                    className={classes.menuItem}
                  >
                    {contentTypesAlias[type]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Thumbnail
              fileName={thumbnail.fileName}
              preview={thumbnail.preview}
              onChange={thumbnail.onChange}
            />
            <FileInput
              fileName={contentFile.fileName}
              onChange={contentFile.onChange}
              name="content"
              errorText={contentFile.error}
              error={!!contentFile.error}
              inputProps={{
                accept: fileAccept
              }}
              buttonText="Upload content"
              helperText="Maximum file size 1 GB"
              className={classes.fileInput}
            />
          </Grid>
        </Grid>
        <Button
          color="secondary"
          disabled={loading}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </form>
    </>
  )
}

export default withStyles(styles)(
  DefaultContentDetail
)