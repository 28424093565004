import { useCallback, useState, useEffect } from 'react';
import useDeleteDialog from './useDeleteDialog';

export default function useDeleteWithContainer(container) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false)

  const handleDelete = useCallback(async (item) => {
    setLoading(true);
    try {
      await container.delete(item);
      setSuccess(true);
    } finally {
      setLoading(false);
    }
  }, [container]);

  const deleteDialog = useDeleteDialog(handleDelete);

  useEffect(() => {
    if (!loading && success) {
      setSuccess(false);
      deleteDialog.handleClose();
    }
  }, [setLoading, deleteDialog.handleClose, loading, success]);

  return { ...deleteDialog, loading };
}