export function WrongPasswordError(message) {
  Error.call(this, message);
  this.name = "WrongPasswordError";
  this.message = message;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, WrongPasswordError);
  } else {
    this.stack = (new Error()).stack;
  }
}

WrongPasswordError.prototype = Object.create(Error.prototype);