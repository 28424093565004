import ModelWithFiles from "../base/ModelWithFiles";
import ValidationError from "../base/ValidationError";

export default class MarkerModel extends ModelWithFiles {

  mapProps({ _id, name, fileName, width, contentId }) {
    
    Object.assign(this, {
      id: this.id || _id,
      fileName: this.fileName || fileName,
      contentId,
      name,
      width,
    });
  }

  getObject() {
    const { id, name, fileName, width, contentId } = this; 
    return { _id: id, name, fileName, width, contentId };
  }

  getFilesToUpload() {
    return {
      fileName: this.fileName,
    }
  }

  validate() {
    const errors = {}
    let isValid = true;
    if (!this.fileName) {
      errors.fileName = 'Preview is required';
      isValid = false;
    }
    this.width = parseFloat(this.width);
    if (isNaN(this.width) || this.width <= 0) {
      errors.width = 'Value must be float and greater than 0';
      isValid = false;
    }
    if (!this.contentId) {
      errors.contentId = 'Content is required'
      isValid = false;
    }

    if (!isValid) {
      throw new ValidationError(errors);
    }
  }
}
