import ModelWithFiles from "../base/ModelWithFiles";

export default class BundleContentModel extends ModelWithFiles {

  mapProps({
    text,
    thumbnail,
    type,
    _id,
    name,
    fileNameAndroid,
    fileNameIos
  }) {

    const newPropsObject = {
      thumbnail: thumbnail || this.thumbnail,
      id: _id || this.id,
      text: text || this.text,
      type,
      name,
      fileNameAndroid: fileNameAndroid || this.fileNameAndroid,
      fileNameIos: fileNameIos || this.fileNameIos,
    }
    Object.assign(this, newPropsObject);
  }

  getObject() {
    const {
      text,
      thumbnail,
      type,
      name,
      fileNameAndroid,
      fileNameIos
    } = this;
    return {
      text,
      thumbnail,
      type,
      name,
      fileNameAndroid,
      fileNameIos
    };
  }

  getFilesToUpload() {
    const files = {
      thumbnail: this.thumbnail,
      fileNameAndroid: this.fileNameAndroid,
      fileNameIos: this.fileNameIos,
    }
    return files;
  }

  setFilenames({ thumbnail, fileNameAndroid, fileNameIos }) {
    this.thumbnail = thumbnail
    this.fileNameAndroid = fileNameAndroid
    this.fileNameIos = fileNameIos
  }
}
