import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    position: 'absolute !important',
    clip: 'rect(1px, 1px, 1px, 1px)',
    padding: '0 !important',
    border: '0 !important',
    height: '1px !important', 
    width: '1px !important', 
    overflow: 'hidden'
  }
})

const VisuallyHidden = ({
  component: Component,
  children,
  className,
  classes,
  ...rest
}) => {
  return (
    <Component
      {...rest}
      className={classNames(classes.root, className)}
    >
      {children}
    </Component>
  );
};

VisuallyHidden.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string, PropTypes.node
  ]),
  classNames: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

VisuallyHidden.defaultProps = {
  component: 'div'
}

export default withStyles(styles)(VisuallyHidden);