import BaseModel from "../base/BaseModel";
import { designElementsTypes } from "../../constants";
import ValidationError from "../base/ValidationError";

export default class TextElementModel extends BaseModel {

  mapProps({ _id, name, type, text, textLengthLimit }) {
    if (type === designElementsTypes.NUMBER) {
      text = parseInt(text);
    }
    type = type || this.type;
    if (type === designElementsTypes.NUMBER) {
      text = parseInt(text);
    }
    Object.assign(this, {
      id: this.id || _id,
      name: this.name || name,
      type: this.type || type,
      textLengthLimit: this.textLengthLimit || textLengthLimit,
      text,
    });
  }

  getObject() {
    const { text } = this; 
    return { text };
  }

  validate() {
    const { type, textLengthLimit, text } = this;
    if (type !== designElementsTypes.NUMBER && text.length >= textLengthLimit) {
      throw new ValidationError({
        text: `Maximum text length is ${textLengthLimit}`
      });
    }
    if (type === designElementsTypes.NUMBER && (typeof text !== "number" || isNaN(text))) {
      throw new ValidationError({
        text: `Integer value required`
      });
    }
  }
}
