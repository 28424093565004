import React from 'react';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const CustomToolbar = ({ children, ...rest }) => {
  return (
    <Toolbar {...rest}>
      <Typography component="h2" variant="h6">
        {children}
      </Typography>
    </Toolbar>
  );
};

export default CustomToolbar;