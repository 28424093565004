import CropFreeIcon from '@material-ui/icons/CropFree';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LockIcon from '@material-ui/icons/Lock';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import TextsmsIcon from '@material-ui/icons/Textsms';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BarIcon from '@material-ui/icons/BarChart';
import ContentList from "../layouts/Content";
import MarkersList from "../layouts/Markers";
import ContentDetail from '../layouts/ContentDetail/ContentWrapper';
import MarkerDetail from '../layouts/MarkerDetail';
import TextDesignElements from '../layouts/TextDesignElements';
import TextElementDetail from '../layouts/TextElementDetail';
import MessageDesignElements from '../layouts/MessageDesignElements';
import MessageDetail from '../layouts/MessageDetail';
import PictureDesignElements from '../layouts/PictureDesignElements';
import PictureElementDetail from '../layouts/PictureElementDetail';
import GroundElemet from '../layouts/GroundElement/GroundElemet';
import ChangePassword from '../layouts/ChangePassword/ChangePassword';
import Analytics from '../layouts/Analytics/Analytics';

const routes = [
  {
    invisible: true,
    component: ContentDetail,
    path: "/content/:id",
  },
  {
    invisible: true,
    component: ContentDetail,
    path: "/content"
  },
  {
    invisible: true,
    component: MarkerDetail,
    path: "/marker/:id"
  },
  {
    invisible: true,
    component: MarkerDetail,
    path: "/marker"
  },
  {
    sidebarIcon: CropFreeIcon,
    sidebarText: "Markers",
    component: MarkersList,
    path: "/markers"
  },
  {
    invisible: true,
    component: TextElementDetail,
    path: "/text-design-elements/:id"
  },
  {
    invisible: true,
    component: MessageDetail,
    path: "/message-design-elements/:id"
  },
  {
    invisible: true,
    component: PictureElementDetail,
    path: "/picture-design-elements/:id"
  },
  {
    sidebarIcon: AttachmentIcon,
    sidebarText: "Content",
    component: ContentList,
    path: "/contents"
  },
  {
    sidebarIcon: TextFormatIcon,
    sidebarText: "Text Elements",
    component: TextDesignElements,
    path: "/text-design-elements"
  },
  {
    sidebarIcon: TextsmsIcon,
    sidebarText: "Message Elements",
    component: MessageDesignElements,
    path: "/message-design-elements"
  },
  {
    sidebarIcon: InsertPhotoIcon,
    sidebarText: "Pitcure Elements",
    component: PictureDesignElements,
    path: "/picture-design-elements"
  },
  {
    sidebarIcon: AspectRatioIcon,
    sidebarText: "Ground Element",
    component: GroundElemet,
    path: "/ground-element"
  },
  {
    sidebarIcon: BarIcon,
    sidebarText: "Analytics",
    component: Analytics,
    path: "/analytics",
  },
  {
    divider: true,
  },
  {
    sidebarIcon: LockIcon,
    sidebarText: "Change password",
    component: ChangePassword,
    path: "/change-password",
  },
  {
    invisible: true,
    redirect: true,
    to: "/markers"
  },
]

export default function getRoutes(campaignId) {
  if (campaignId) {
    const pattern = `/campaign/:campaignId`;
    return routes
      .filter(({ path }) => path !== '/change-password')
      .map(item => {
        item = { ...item };
        if (item.path) {
          item.path = pattern + item.path;
        }
        if (item.to) {
          item.to = pattern + item.to;
        }
        return item;
      })
  }
  return routes;
}
export  function getLinks(campaignId) {
  if (campaignId) {
    const pattern = `/campaign/${campaignId}`;
    return routes
      .filter(({ path }) => path !== '/change-password')
      .map(item => {
        item = { ...item };
        if (item.path) {
          item.path = pattern + item.path;
        }
        if (item.to) {
          item.to = pattern + item.to;
        }
        return item;
      })
  }
  return routes;
}