import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BasePapper from '../../Components/BasePapper';
import FadedProgress from '../../Components/FadedProgress';
import CustomToolbar from '../../Components/CustomToolbar';
import useTextState from '../../hooks/useTextState';
import equalToValidation, { combineValidators, fieldIsRequired, minLengthValidator } from '../../validators/validators';
import { useChangePassword } from './hooks';
import { WrongPasswordError } from './WrongPasswordError';
import { withSnackbar } from "notistack";

const styles = theme => ({
  form: {
    width: '50%',
  },
  submit: {
    marginTop: theme.spacing.unit * 2,
  }
});

const minLengthPasswordValidator = minLengthValidator(8, 'The minimum password length is 8');
const passwordValidator = combineValidators(
  fieldIsRequired('Password is required'),
  minLengthPasswordValidator
);
const passwordConfirmValidator = equalToValidation('password1', 'Passwords must match');

const validators = {
  password1: passwordValidator,
  password2: passwordConfirmValidator,
}

const ChangePassword = ({ classes, enqueueSnackbar }) => {

  const { state, onChange, errors, setErrors, setState, validate } = useTextState({
    oldPassword: "",
    password1: "",
    password2: "",
  }, validators);

  const { oldPassword, password1, password2 } = state;
  
  const [changePassword, loading] = useChangePassword({ oldPassword, newPassword: password1 });
  const handleSubmit = useCallback(async e => {
    e.preventDefault();
    const isValid = !validate();
    if (!isValid) { return; }
    try {
      await changePassword();
      enqueueSnackbar('Password successfully changed', { variant: 'success' });
      setState({
        password1: "",
        password2: "",
        oldPassword: "",
      });
    } catch (e) {
      if (e instanceof WrongPasswordError) {
        setState({
          ...state,
          password1: "",
          password2: "",
        });
        setErrors({
          ...errors,
          oldPassword: e.message
        });
        enqueueSnackbar('Incorrect current password', { variant: 'error' });
      } else {
        throw e;
      }
    }
  }, [changePassword, errors, setErrors, state]);

  return (
    <BasePapper>
      <FadedProgress
        loading={loading}
      />
      <CustomToolbar disableGutters>
        Change password
      </CustomToolbar>
      <form className={classes.form} onSubmit={handleSubmit} >
        <TextField
          name="oldPassword"
          value={oldPassword}
          onChange={onChange}
          label="Current password"
          margin="normal"
          autoFocus
          type="password"
          required
          fullWidth
          error={!!errors.oldPassword}
          helperText={errors.oldPassword}
        />
        <TextField
          name="password1"
          value={password1}
          onChange={onChange}
          label="New password"
          margin="normal"
          type="password"
          error={!!errors.password1}
          helperText={errors.password1}
          required
          fullWidth
        />
        <TextField
          name="password2"
          value={password2}
          onChange={onChange}
          label="Confirm password"
          margin="normal"
          type="password"
          error={!!errors.password2}
          helperText={errors.password2}
          required
          fullWidth
        />
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          disabled={loading}
          className={classes.submit}
        >
          Change password
        </Button>
      </form>
    </BasePapper>
  );
};

export default withStyles(styles)(
  withSnackbar(ChangePassword)
);