import { decorate, action, observable } from "mobx";
import BaseModelContainer from './BaseModelsContainer';

class PaginatedModelContainer extends BaseModelContainer {
  count = 0;

  async loadList(options={}) {
    const { offset=0, limit=15, search, ...rest } = options;
    const { items, count } = await this.netService
      .getList({ skip: offset * limit, limit, searchstring: search || undefined, ...rest });
    this.list = items.map(item => new this.Model(item));
    this.count = Math.ceil(count / limit);
  }
}

decorate(PaginatedModelContainer, {
  loadList: action,
  count: observable,
}); 

export default PaginatedModelContainer;