import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BasePapper from '../../Components/BasePapper';
import CustomToolbar from '../../Components/CustomToolbar';
import CustomLinearProgress from '../../Components/CustomLinearProgress';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
// import NumberOfInstallations from './NumberOfInstallations';
import PhotosAndVideos from './PhotosAndVideos';
import AllData from './AllData';
import { getAnalytics } from '../../api/analytics';
import { useSession } from '../../hooks/useStores';
import useRouter from 'use-react-router';

const styles = theme => ({
  section: {
    '& + &': {
      marginTop: theme.spacing.unit * 4,
    }
  }
});

const Analytics = ({ classes }) => {
  const { isSuper } = useSession();
  const { campaignId } = useRouter().match.params;
  const [analytics, setAnalytics] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    getAnalytics({ spaceId: campaignId })
    .then(({ data }) => {
      if (isSuper && !campaignId) {
        setAnalytics(data.globalAnalytics);
      } else {
        setAnalytics(data.campaignAnalytics[0]);
      }
    }).finally(() => { setLoading(false) });
  }, [isSuper, campaignId]);

  const allData = useMemo(() => {
    if (analytics) {
      return [
        { name: 'Time in app', value: `${analytics.timeInApp} minutes` },
        { name: 'Number of App launches', value: analytics.appLaunches },
      ]
    }
    return []
  }, [analytics]);

  return (
    <BasePapper>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit>
        <CustomLinearProgress />
      </Fade>
      <CustomToolbar disableGutters>
        Analytics
      </CustomToolbar>
      {analytics && (
        <>
          {/* <section className={classes.section}>
            <Typography variant="h5" component="h2" align="center">
              Number of installations
          </Typography>
            <NumberOfInstallations playMarket={1234} appStore={3214} />
          </section> */}
          <section className={classes.section}>
            <Typography variant="h5" component="h2" align="center">
              Photos & Videos
          </Typography>
            <PhotosAndVideos data={analytics} />
          </section>
          <section className={classes.section}>
            <Typography variant="h5" component="h2" align="center">
              Other
          </Typography>
            <AllData data={allData} />
          </section>
        </>
      )}
    </BasePapper>
  );
};

export default withStyles(styles)(Analytics)