import React, { PureComponent } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';
import debounce from 'debounce-async';
import PropTypes from 'prop-types';
import MarkersModelContainer from '../../store/Marker/MarkerModelsContainer';
import fileHandler from '../../helpers/fileHandler';
import isFloatHandler from '../../helpers/isFloatHandler';
import ValidationError from '../../store/base/ValidationError';
import { isFileLengthValid } from './validator';
import { maxMarkerLength } from '../../constants';
import addCampaignIdToRoute from '../../helpers/addCampaignIdToRoute';

export default function MarkerDetailContainer(Wrapper) {
  class MarkerDetailWrapper extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        name: "",
        thumbnailFileName: "",
        thumbnailUrl: "",
        width: 0,
        errors: {
          content: false,
          thumbnail: false,
          width: false,
        },
        loading: false,
        content: null,
        redirectTo: '',
      }
      this.thumbnail = null;
      this.markerItem = null;
      this.loadContentOptions = debounce(this.loadContentOptions, 600);
      this.handleThumbnailFile = fileHandler('thumbnail', false, true).bind(this);
    }

    async componentDidMount() {
      const { markers, match: { params: { id } } } = this.props;
      if (id) {
        this.setState({ loading: true });
        try {
          this.markerItem = await markers.getDetail(id);
          this.contentToState();
        } finally {
          this.setState({ loading: false })
        }
      }
    }

    componentWillUnmount() {
      const { thumbnailUrl } = this.state;
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    }

    contentToState() {
      const {
        name,
        contentId,
        fileName,
        width,
      } = this.markerItem;

      this.setState({
        name,
        thumbnailUrl: fileName,
        content: contentId ? {
          label: contentId.name,
          value: contentId._id
        } : null,
        width
      });
    }

    handleSubmit = async e => {
      e.preventDefault();
      if (this.state.loading) {
        return;
      }
      this.setState({ loading: true });
      const {
        name,
        content,
        width,
      } = this.state;
      const fileName = this.thumbnail;
      const marker = {
        name,
        contentId: (content && content.value) || undefined,
        width: parseFloat(width),
        fileName
      }
      const { campaignId } = this.props.match.params;
      try {
        if (this.markerItem) {
          await this.updateItem(marker, { spaceId: campaignId });
        } else {
          await this.createItem(marker, { spaceId: campaignId });
        }
      } catch(e) {
        if (e instanceof ValidationError) {
          const {
            fileName: thumbnail,
            contentId: content,
          } = e.componentErrors;
          const errors = {
            ...e.componentErrors,
            thumbnail,
            content
          }
          this.setState({
            errors: {
              ...this.state.errors,
              ...errors,
            }
          });
        } else {
          throw e;
        }
      } finally {
        this.setState({
          loading: false
        });
      }
    }
    
    async updateItem(data, options) {
      await this.props.markers
        .update(this.markerItem, data, options);
    }

    async createItem(data, options) {
      const item = await this.props.markers.create(data, options)
      if (!item) {
        return;
      }
      const { id } = item;
      const { campaignId } = this.props.match.params;
      const redirectTo = addCampaignIdToRoute(`/marker/${id}`, campaignId);
      this.setState({ redirectTo, });
    }

    handleChange = e => {
      const { target } = e;
      const { name, value } = target;
      if (name === 'thumbnail') {
        if (isFileLengthValid(e, maxMarkerLength)) {
          this.handleThumbnailFile(e)
          this.setState({
            errors: {
              thumbnail: false
            }
          })
        } else {
          this.setState({
            errors: {
              thumbnail: "Max file length is 2 MB"
            }
          })
        }
        return;
      }
      if (name === 'width' && !isFloatHandler(value)) {
        return;
      }
      this.setState({
        [name]: value,
        errors: {
          ...this.state.errors,
          [name]: false,
        }
      });
    }

    loadContentOptions = async search => {
      const { campaignId } = this.props.match.params;
      return this.props.content.loadList({
        limit: 30,
        search: search || undefined,
        spaceId: campaignId,
      });
    }

    handleLoadContent = async search => {
      await this.loadContentOptions(search);
      const items = this.props.content.list.map(item => ({
        label: item.name,
        value: item.id,
      }))
      return items;
    }

    handleContentChange = (selectedItem, node) => {
      this.setState({
        content: selectedItem,
        errors: {
          ...this.state.errors,
          content: false,
        }
      })
    }

    render() {
      const { redirectTo } = this.state;
      if (redirectTo) {
        return <Redirect to={redirectTo}/>
      }
      const { content, ...props } = this.props;
      return (
        <Wrapper
          {...props}
          {...this.state}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          fileAccept=".jpg, .jpeg, .png"
          onLoadOptions={this.handleLoadContent}
          onContentChange={this.handleContentChange}
        />
      );
    }
  }
  
  MarkerDetailContainer.propTypes = {
    marker: PropTypes.instanceOf(MarkersModelContainer),
  };

  return inject('markers', 'notifier', 'content')(
    withRouter(MarkerDetailWrapper)
  );
}
