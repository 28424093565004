import React, { useCallback, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BasePapper from '../../Components/BasePapper';
import FadedProgress from '../../Components/FadedProgress';
import CustomToolbar from '../../Components/CustomToolbar';
import { useCampaigns } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import useTextState from '../../hooks/useTextState';
import { withStyles } from '@material-ui/core';
import useGetDetail from '../../hooks/useGetDetail';

const styles = theme => ({
  form: {
    maxWidth: '50%',
  },
  input: {
    marginTop: theme.spacing.unit * 2,
  },
});

const CampaignDetail = observer(({
  classes,
}) => {
  const campaigns = useCampaigns();
  const { item, loading: loadingDetail } = useGetDetail(campaigns);
  const {
    state: {
      name
    },
    onChange,
    setState,
  } = useTextState({
    name: ""
  });
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirect] = useState();

  useEffect(() => {
    if (item) {
      setState({ name: item.name });
    }
  }, [item]);

  const handleSubmit = useCallback(async(e) => {
    e.preventDefault();
    const data = {
      name
    };
    setLoading(true);
    try {
      if (item) {
        await campaigns.update(item, data);
      } else {
        const result = await campaigns.create(data);
        if (result) {
          setRedirect(`/campaign-detail/${result.id}`);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [campaigns, item, name]);

  if (redirectTo) {
    return <Redirect to={redirectTo}/>
  }

  if (loadingDetail) {
    return null;
  }

  return (
    <BasePapper>
      <FadedProgress
        loading={loading}
      />
      <CustomToolbar disableGutters>
        Campaign
      </CustomToolbar>
      <form
        onSubmit={handleSubmit}
        className={classes.form}
        autoComplete="off"
      >
        <TextField
          name="name"
          value={name}
          onChange={onChange}
          label="Name"
          className={classes.input}
          fullWidth
          required
        />
        <Button
          className={classes.input}
          color="secondary"
          disabled={loading}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </form>
    </BasePapper>
  );
});

export default withStyles(styles)(CampaignDetail);