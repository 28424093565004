import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react'

export function useStores() {
  return useContext(MobXProviderContext)
}

export function useClients() {
  const { clients } = useStores();
  return clients;
}

export function useCampaigns() {
  const { campaigns } = useStores();
  return campaigns;
}

export function useSession() {
  const { session } = useStores();
  return session;
}

export function useContent() {
  const { content } = useStores();
  return content;
}

export function useSelectedCampaign() {
  const { selectedCampaign } = useStores();
  return selectedCampaign;
}